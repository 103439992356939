import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { List, ListItem, Span } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
export var FacetList = styled(List)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  ", "\n  ", "\n"])), function (props) {
  return props.scrollable && "\n    max-height: 208px;\n    overflow-y: auto;\n\n    &:after {\n        content: '';\n        position: absolute;\n        left: 0;\n        bottom: 10px;\n        display: block;\n        width: 100%;\n        height: 10px;\n        background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));\n    }\n  ";
}, function (props) {
  return props.hasSearchBar && props.scrollable && "\n    max-height: 158px;\n    ";
});
export var FacetSubList = styled(FacetList)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding-left: 14px;\n"])));
export var FacetListItem = styled(ListItem)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: ", ";\n  flex-basis: ", "%;\n"])), function (props) {
  return props.padding ? props.padding : "0";
}, function (props) {
  return props.columns ? 100 / props.columns : 100;
});
export var FacetSubListItem = styled(FacetListItem)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  border-bottom: solid 1px ", ";\n  margin-bottom: 5px;\n  &:last-of-type {\n    border: none;\n  }\n"])), themeGet("colors.ui.borderColor"));
export var FacetCount = styled(Span)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: ", ";\n  margin-left: 5px;\n"])), themeGet("colors.typography.subText"));