import { filterObject } from "./filterObject";
export function mapSponsoredFacets(queryObject) {
  var _queryObject$fTrendi, _queryObject$fDelive, _queryObject$fSeller;

  var result = {
    category: queryObject["f[category_correlated]"],
    brandIds: (_queryObject$fTrendi = queryObject["f[trending_brand]"]) === null || _queryObject$fTrendi === void 0 ? void 0 : _queryObject$fTrendi.split(";"),
    minPrice: parseInt(queryObject["f[price_range:min]"]) || undefined,
    maxPrice: parseInt(queryObject["f[price_range:max]"]) || undefined,
    rating: parseInt(queryObject["f[rating_categories]"]) || undefined,
    delivery: (_queryObject$fDelive = queryObject["f[delivery]"]) === null || _queryObject$fDelive === void 0 ? void 0 : _queryObject$fDelive.split(";").map(function (item) {
      return item.toUpperCase();
    }),
    soldBy: (_queryObject$fSeller = queryObject["f[seller_type]"]) === null || _queryObject$fSeller === void 0 ? void 0 : _queryObject$fSeller.split(";").map(function (item) {
      return item.toUpperCase();
    })
  };
  return filterObject(result);
}