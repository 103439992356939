import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["inputContainerProps", "indeterminate", "iconSize", "disabled", "focused", "checked", "defaultChecked", "onChange", "required", "title"];

var _templateObject;

import React from "react";
import { styled, themeGet, useTheme } from "@catchoftheday/theme";
import { useControlled } from "@catchoftheday/utilities";
import { IconSVG } from "../Icon";
import { Span } from "../Text";
import { jsx as ___EmotionJSX } from "@emotion/react";
var OriginalCheckboxInput = styled("input")(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  opacity: 0;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  margin: 0;\n  padding: 0;\n  z-index: 1;\n  &:focus-visible + svg rect {\n    outline: none;\n    stroke: ", ";\n  }\n  &:focus[data-focus-visible-added] + svg rect {\n    outline: none;\n    stroke: ", ";\n  }\n  &:hover + svg rect {\n    stroke: ", ";\n  }\n"])), themeGet("colors.secondaryDark"), themeGet("colors.secondaryDark"), themeGet("colors.secondaryDark"));

var CheckboxIcon = function CheckboxIcon(_ref) {
  var _theme$colors, _theme$colors$ui, _theme$colors2, _theme$colors3, _theme$colors3$ui, _theme$colors4, _theme$colors5;

  var disabled = _ref.disabled,
      checked = _ref.checked,
      indeterminate = _ref.indeterminate,
      iconSize = _ref.iconSize,
      focused = _ref.focused,
      title = _ref.title;
  var theme = useTheme();
  return ___EmotionJSX(IconSVG, {
    viewBox: "0 0 20 20",
    "aria-hidden": "true",
    focusable: "false",
    width: iconSize,
    height: iconSize
  }, title && ___EmotionJSX("title", null, title), ___EmotionJSX("rect", {
    stroke: !indeterminate && !checked && !focused ? theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : (_theme$colors$ui = _theme$colors["ui"]) === null || _theme$colors$ui === void 0 ? void 0 : _theme$colors$ui["borderColor"] : theme === null || theme === void 0 ? void 0 : (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : _theme$colors2["secondary"],
    strokeWidth: "2",
    fill: disabled ? theme === null || theme === void 0 ? void 0 : (_theme$colors3 = theme.colors) === null || _theme$colors3 === void 0 ? void 0 : (_theme$colors3$ui = _theme$colors3["ui"]) === null || _theme$colors3$ui === void 0 ? void 0 : _theme$colors3$ui["borderColor"] : "white",
    x: "1",
    y: "1",
    width: "18",
    height: "18"
  }), ___EmotionJSX("path", {
    display: !disabled && !indeterminate && checked ? "block" : "none",
    stroke: theme === null || theme === void 0 ? void 0 : (_theme$colors4 = theme.colors) === null || _theme$colors4 === void 0 ? void 0 : _theme$colors4["secondary"],
    strokeWidth: "2",
    fill: "none",
    d: "M4.0127 10.1223L7.9375 13.7112L17.1536 4.75439"
  }), ___EmotionJSX("line", {
    display: !disabled && indeterminate ? "block" : "none",
    x1: "4",
    y1: "10",
    x2: "16",
    y2: "10",
    stroke: theme === null || theme === void 0 ? void 0 : (_theme$colors5 = theme.colors) === null || _theme$colors5 === void 0 ? void 0 : _theme$colors5["secondary"],
    strokeWidth: "2"
  }));
};

export var Checkbox = function Checkbox(_ref2) {
  var inputContainerProps = _ref2.inputContainerProps,
      _ref2$indeterminate = _ref2.indeterminate,
      indeterminate = _ref2$indeterminate === void 0 ? false : _ref2$indeterminate,
      iconSize = _ref2.iconSize,
      _ref2$disabled = _ref2.disabled,
      disabled = _ref2$disabled === void 0 ? false : _ref2$disabled,
      _ref2$focused = _ref2.focused,
      focused = _ref2$focused === void 0 ? false : _ref2$focused,
      checkedProps = _ref2.checked,
      _ref2$defaultChecked = _ref2.defaultChecked,
      defaultCheckedProps = _ref2$defaultChecked === void 0 ? false : _ref2$defaultChecked,
      onChangeProps = _ref2.onChange,
      required = _ref2.required,
      title = _ref2.title,
      otherInputProps = _objectWithoutProperties(_ref2, _excluded);

  var _useControlled = useControlled(defaultCheckedProps, checkedProps),
      _useControlled2 = _slicedToArray(_useControlled, 2),
      checked = _useControlled2[0],
      setChecked = _useControlled2[1];

  var onChangeHandler = function onChangeHandler(e) {
    setChecked(e.currentTarget.checked);

    if (onChangeProps) {
      onChangeProps(e);
    }
  };

  return ___EmotionJSX(Span, _extends({
    position: "relative"
  }, inputContainerProps), ___EmotionJSX(OriginalCheckboxInput, _extends({
    type: "checkbox",
    disabled: disabled,
    checked: checked,
    onChange: onChangeHandler,
    "aria-required": required,
    "aria-disabled": disabled,
    required: required
  }, otherInputProps)), ___EmotionJSX(CheckboxIcon, {
    disabled: disabled,
    checked: checked,
    focused: focused,
    indeterminate: indeterminate,
    iconSize: iconSize,
    title: title
  }));
};
Checkbox.displayName = "Checkbox";
export default Checkbox;