import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { CustomSelect, SortIcon, Truncate } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { useShallowSelector } from "@search-app/store";
import { isSearchLoading, sortOptions, sortOptionSelected } from "@search-app/store/search/selectors";
import { routeToQueryParam } from "@search-app/utilities/router";
import { jsx as ___EmotionJSX } from "@emotion/react";
var SortOption = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 15px;\n  line-height: 1;\n  cursor: pointer;\n  font-weight: ", ";\n  font-size: ", ";\n  border-bottom: 1px solid ", ";\n  :hover {\n    color: black;\n    background-color: ", ";\n  }\n  ", "\n"])), themeGet("fontWeights.light"), themeGet("fontSizes.sm"), themeGet("colors.ui.borderColor"), themeGet("colors.ui.borderColor"), function (props) {
  return props.isSelected && "\n      background-color: ".concat(themeGet("colors.secondary")(props), "; color: white;\n      &:hover {background-color: ").concat(themeGet("colors.secondary")(props), "; color: white;}\n    ");
});
export var SearchSortOptionsInternal = function SearchSortOptionsInternal(_ref) {
  var _ref$isLoading = _ref.isLoading,
      isLoading = _ref$isLoading === void 0 ? false : _ref$isLoading,
      options = _ref.options,
      optionSelected = _ref.optionSelected;
  var optionItems = options.map(function (option) {
    return {
      label: option.title,
      component: ___EmotionJSX(SortOption, {
        key: option.value,
        title: option.title,
        isSelected: optionSelected.title == option.title
      }, option.title),
      onSelect: function onSelect() {
        return routeToQueryParam([{
          key: "sort",
          value: option.value
        }, {
          key: "page",
          value: 1
        }]);
      }
    };
  });
  return ___EmotionJSX(CustomSelect, {
    "aria-label": "Sort Options",
    disabled: isLoading,
    customSelectItems: optionItems,
    containerProps: {
      maxWidth: "250px",
      width: {
        xs: "auto",
        md: "250px"
      },
      height: {
        xs: "40px",
        md: "45px"
      },
      margin: "0 auto"
    },
    height: {
      xs: "40px",
      md: "45px"
    },
    prefixIcon: SortIcon,
    focusBorderColor: {
      xs: "none",
      md: "secondary"
    },
    style: {
      textTransform: "capitalize",
      color: "secondary",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center"
    },
    value: optionSelected.value,
    menuProps: {
      boxShadow: "0 5px 5px 1px rgba(0, 0, 0, 0.15)"
    },
    borderColor: {
      xs: "transparent",
      md: "ui.borderColor"
    },
    py: {
      xs: "0",
      md: "auto"
    },
    borderRadius: "none",
    defaultSelectedIndex: options.map(function (o) {
      return o.value;
    }).indexOf(optionSelected.value)
  }, ___EmotionJSX(Truncate, {
    fontWeight: "inherit",
    fontSize: "base"
  }, "Sort: ", optionSelected.title));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      isLoading: isSearchLoading(state),
      // TODO: remove this type assertion
      options: sortOptions(state),
      // TODO: remove this type assertion
      optionSelected: sortOptionSelected(state)
    };
  });
};

export var SearchSortOptions = function SearchSortOptions(props) {
  var state = useReduxState();
  return ___EmotionJSX(SearchSortOptionsInternal, _extends({}, state, props));
};