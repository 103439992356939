import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useEffect, useState } from "react";
import { LogRangeSlider, RangeSlider } from "@catchoftheday/cg-components";
import { usePrevious } from "@catchoftheday/utilities";
import { SEARCH_FACET_TYPE_RANGE } from "@search-app/helpers/search/search.helpers.facets";
import { SEARCH_URL_PARAM_FACET } from "@search-app/helpers/search/search.helpers.params";
import { facetAppliedCount } from "@search-app/utilities/facets/applied";
import { SearchFacetsBody } from "../SearchFacetsBody";
import { SearchFacetsContainer } from "../SearchFacetsContainer";
import { SearchFacetsHeader } from "../SearchFacetsHeader";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchFacetsRange = function SearchFacetsRange(_ref) {
  var facet = _ref.facet,
      isExpanded = _ref.isExpanded,
      _ref$isClearAll = _ref.isClearAll,
      isClearAll = _ref$isClearAll === void 0 ? false : _ref$isClearAll,
      onChange = _ref.onChange,
      onExpandChange = _ref.onExpandChange,
      onClear = _ref.onClear,
      isLoading = _ref.isLoading,
      searchTimeout = _ref.searchTimeout,
      noncollapsible = _ref.noncollapsible;
  var id = facet.id,
      name = facet.name,
      min = facet.min,
      max = facet.max,
      selectedMin = facet.selectedMin,
      selectedMax = facet.selectedMax,
      _facet$settings = facet.settings,
      settings = _facet$settings === void 0 ? {} : _facet$settings;
  var _settings$step = settings.step,
      step = _settings$step === void 0 ? 1 : _settings$step,
      _settings$mode = settings.mode,
      mode = _settings$mode === void 0 ? 3 : _settings$mode,
      _settings$unit = settings.unit,
      unit = _settings$unit === void 0 ? "" : _settings$unit;
  var stateSelectedMin = selectedMin ? Math.max(selectedMin, min) : min;
  var stateSelectedMax = selectedMax ? Math.min(selectedMax, max) : max;

  var _useState = useState(stateSelectedMin),
      valuesMin = _useState[0],
      setValuesMin = _useState[1];

  var _useState2 = useState(stateSelectedMax),
      valuesMax = _useState2[0],
      setValuesMax = _useState2[1];

  var displayUnitMin = "Min ".concat(unit === "$" ? "$".concat(min) : "".concat(min, " ").concat(unit));
  var displayUnitMax = "Max ".concat(unit === "$" ? "$".concat(max) : "".concat(max, " ").concat(unit));
  var facetId = "".concat(SEARCH_URL_PARAM_FACET, "[").concat(id, "]");
  var selectedLabel = valuesMin !== min || valuesMax !== max ? "".concat(unit).concat(valuesMin, " - ").concat(unit).concat(valuesMax) : "";
  var prevFacet = usePrevious(facet);
  var totalSelected = facetAppliedCount(facet);

  var checkNewValue = function checkNewValue(value, rangeStartDefault, rangeEnd) {
    if (!value || typeof value === "string" && value.trim() === "") {
      return rangeStartDefault;
    }

    var min = Math.min(rangeStartDefault, rangeEnd);
    var max = Math.max(rangeStartDefault, rangeEnd);
    var newValue = typeof value === "string" ? parseInt(value) : value;
    return newValue >= min && newValue <= max ? newValue : rangeStartDefault;
  };

  var setMinMaxValues = function setMinMaxValues(min, max) {
    setValuesMin(min);
    setValuesMax(max);
  };

  var onClearHandler = function onClearHandler() {
    if (onClear) {
      setMinMaxValues(min, max);
      onClear({
        facet: id,
        type: SEARCH_FACET_TYPE_RANGE
      });
    }
  };

  var handleChange = function handleChange(values) {
    var compareMin = min < valuesMin ? valuesMin : min;
    var compareMax = max > valuesMax ? valuesMax : max;
    setMinMaxValues(values[0], values[1]);

    if (typeof onChange === "function" && (values[0] !== compareMin || values[1] !== compareMax) && !isClearAll) {
      onChange({
        facet: facet.id,
        value: values,
        type: SEARCH_FACET_TYPE_RANGE
      });
    }
  };

  useEffect(function () {
    var stateSelectedMin = selectedMin ? Math.max(selectedMin, min) : min;
    var stateSelectedMax = selectedMax ? Math.min(selectedMax, max) : max;

    if (valuesMin !== stateSelectedMin) {
      setValuesMin(stateSelectedMin);
    }

    if (valuesMax !== stateSelectedMax) {
      setValuesMax(stateSelectedMax);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [selectedMin, selectedMax]);
  useEffect(function () {
    var min = facet.min,
        max = facet.max,
        selectedMin = facet.selectedMin,
        selectedMax = facet.selectedMax;

    if (!prevFacet) {
      return;
    }

    var prevMin = prevFacet.min,
        prevMax = prevFacet.max,
        prevSelectedMin = prevFacet.selectedMin,
        prevSelectedMax = prevFacet.selectedMax;
    var newValuesMin = valuesMin;
    var newValuesMax = valuesMax;

    if (isClearAll || !selectedMin && !selectedMax && prevSelectedMin && prevSelectedMax) {
      newValuesMin = min;
      newValuesMax = max;
      setMinMaxValues(newValuesMin, newValuesMax);
    } else if (min !== prevMin || max !== prevMax) {
      if (valuesMin === prevMin) {
        newValuesMin = min;
      } else {
        newValuesMin = checkNewValue(valuesMin, min, max);
      }

      if (valuesMax === prevMax) {
        newValuesMax = max;
      } else {
        newValuesMax = checkNewValue(valuesMax, max, min);
      }

      setMinMaxValues(newValuesMin, newValuesMax);
    }
  }, [facet, isClearAll, prevFacet, valuesMax, valuesMin]);

  if (min === max) {
    return null;
  }

  var rangeProps = {
    minMaxValues: [min, max],
    defaultValues: [valuesMin, valuesMax],
    onChange: handleChange,
    disabled: isLoading,
    mode: mode,
    step: step,
    unitType: unit,
    displayUnitMin: displayUnitMin,
    displayUnitMax: displayUnitMax,
    inputProps: {
      pl: unit === "$" ? "1.125rem" : "1.875rem",
      maxWidth: "100px"
    },
    containerProps: {
      px: "16px"
    }
  };
  return ___EmotionJSX(SearchFacetsContainer, {
    id: facetId
  }, ___EmotionJSX(SearchFacetsHeader, {
    id: facetId,
    isExpanded: isExpanded,
    title: name,
    searchTimeout: searchTimeout,
    selectedLabel: selectedLabel,
    onClear: onClearHandler,
    onExpandChange: onExpandChange,
    noncollapsible: noncollapsible,
    totalSelected: totalSelected
  }), ___EmotionJSX(SearchFacetsBody, {
    isExpanded: isExpanded
  }, id === "price_range" ? ___EmotionJSX(LogRangeSlider, _extends({}, rangeProps, {
    step: 5,
    rangeContainerProps: {
      px: "24px"
    }
  })) : ___EmotionJSX(RangeSlider, rangeProps)));
};