import React from "react";
import { Box, Flex, Heading, Link } from "@catchoftheday/cg-components";
import { usePopularSearchesQuery } from "@search-app/generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PopularSearchLinks = function PopularSearchLinks(_ref) {
  var _data$popularSearches;

  var slug = _ref.slug;

  var _usePopularSearchesQu = usePopularSearchesQuery({
    variables: {
      input: {
        slug: slug
      }
    }
  }),
      data = _usePopularSearchesQu.data,
      error = _usePopularSearchesQu.error;

  var popularSearchLinks = data === null || data === void 0 ? void 0 : (_data$popularSearches = data.popularSearches) === null || _data$popularSearches === void 0 ? void 0 : _data$popularSearches.links;

  if (!(popularSearchLinks !== null && popularSearchLinks !== void 0 && popularSearchLinks.length) || error) {
    return null;
  }

  return ___EmotionJSX(Box, {
    bg: "ui.backgroundColor",
    p: "15px"
  }, ___EmotionJSX(Box, {
    maxWidth: "1340px",
    mx: "auto"
  }, ___EmotionJSX(Heading.h4, {
    color: "typography.subText",
    textAlign: {
      xs: "left",
      lg: "center"
    },
    my: "15px"
  }, "Popular Searches"), ___EmotionJSX(Flex, {
    flexDirection: "row",
    justifyContent: {
      xs: "flex-start",
      lg: "center"
    },
    flexWrap: "wrap"
  }, popularSearchLinks.map(function (link) {
    return (link === null || link === void 0 ? void 0 : link.url) && ___EmotionJSX(Link, {
      href: link.url,
      key: link.text,
      pl: {
        xs: "0px",
        lg: "20px"
      },
      pr: "20px",
      py: "10px",
      fontSize: "xs",
      fontWeight: "light"
    }, link.text);
  }))));
};