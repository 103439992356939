import React from "react";
import { Box, Button, Flex, Heading, Modal } from "@catchoftheday/cg-components";
import { useDispatch, useShallowSelector } from "@search-app/store";
import { closeAdultModal } from "@search-app/store/adultModal/actions";
import { isAdultModalOpen } from "@search-app/store/search/selectors";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AdultPopup = function AdultPopup() {
  var _useReduxState = useReduxState(),
      open = _useReduxState.open;

  var _useReduxActions = useReduxActions(),
      closeModal = _useReduxActions.closeModal;

  return ___EmotionJSX(Modal, {
    shouldCloseOnOverlayClick: false,
    isOpen: open,
    onRequestClose: function onRequestClose() {
      return closeModal(false);
    },
    contentProps: {
      p: 0
    },
    maxWidth: "600px",
    enableDefaultResponsiveStyles: false
  }, ___EmotionJSX(Box, {
    borderBottom: "1px solid #ddd",
    backgroundColor: "#f7f7f7",
    p: "20px"
  }, ___EmotionJSX(Heading.h4, {
    lineHeight: 1,
    fontSize: "1.25rem",
    fontWeight: "600px",
    paddingRight: "36px",
    textAlign: "left",
    m: 0
  }, "You must be 18+ to view this sale")), ___EmotionJSX(Box, {
    marginBottom: 5
  }, ___EmotionJSX(Heading.h4, {
    fontSize: "1.25rem",
    textAlign: "center"
  }, "Are you over 18 years old?"), ___EmotionJSX(Flex, {
    justifyContent: "center"
  }, ___EmotionJSX(Button, {
    variant: "error",
    fontSize: "1rem",
    textAlign: "center",
    marginLeft: "10px",
    marginRight: "10px",
    onClick: function onClick() {
      return closeModal(false);
    }
  }, "No"), ___EmotionJSX(Button, {
    variant: "primary",
    fontSize: "1rem",
    textAlign: "center",
    marginLeft: "10px",
    marginRight: "10px",
    onClick: function onClick() {
      return closeModal(true);
    }
  }, "Yes"))));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      open: isAdultModalOpen(state)
    };
  });
};

var useReduxActions = function useReduxActions() {
  var dispatch = useDispatch();

  var closeModal = function closeModal(isAdult) {
    if (isAdult) {
      dispatch(closeAdultModal());
    } else {
      // As the adult modal is only connected to the search spa window.location is needed to redirect to relevant home page
      window.location.assign("/");
    }
  };

  return {
    closeModal: closeModal
  };
};