import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
import { Box } from "../Box";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function SliderHandle(_ref) {
  var _ref$domain = _slicedToArray(_ref.domain, 2),
      min = _ref$domain[0],
      max = _ref$domain[1],
      _ref$handle = _ref.handle,
      id = _ref$handle.id,
      value = _ref$handle.value,
      percent = _ref$handle.percent,
      getHandleProps = _ref.getHandleProps,
      disabled = _ref.disabled,
      index = _ref.index;

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Box, _extends({
    position: "absolute",
    style: {
      transform: "translate(-50%, -50%)",
      zIndex: 5,
      WebkitTapHighlightColor: "rgba(0,0,0,0)"
    },
    left: "".concat(percent, "%"),
    width: "28px",
    height: "42px",
    cursor: disabled ? "not-allowed" : "pointer",
    backgroundColor: "none",
    className: "range-slider-handle"
  }, getHandleProps(id))), ___EmotionJSX(Box, {
    position: "absolute",
    style: {
      transform: "translate(-50%, -50%)",
      zIndex: 2
    },
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    border: "solid 2px",
    borderColor: "".concat(disabled ? "typography.inactive" : "secondary"),
    left: "".concat(percent, "%"),
    backgroundColor: "white",
    role: "slider",
    "aria-labelledby": index === 0 ? "minvalue" : "maxvalue",
    "aria-valuemin": min,
    "aria-valuemax": max,
    "aria-valuenow": value,
    "aria-disabled": disabled
  }));
}
export function SliderTrack(_ref2) {
  var source = _ref2.source,
      target = _ref2.target,
      getTrackProps = _ref2.getTrackProps,
      disabled = _ref2.disabled;
  return ___EmotionJSX(Box, _extends({
    position: "absolute",
    transform: "translate(0%, -50%)",
    height: "2px",
    style: {
      zIndex: 1
    },
    backgroundColor: disabled ? "typography.inactive" : "secondary",
    top: "-1px",
    cursor: disabled ? "not-allowed" : "pointer",
    left: "".concat(source.percent, "%"),
    width: "".concat(target.percent - source.percent, "%")
  }, getTrackProps()));
}
export function SliderTick(_ref3) {
  var tick = _ref3.tick,
      count = _ref3.count,
      unitType = _ref3.unitType;
  return ___EmotionJSX("div", null, ___EmotionJSX(Box, {
    position: "absolute",
    marginTop: "14px",
    width: "1px",
    height: "5px",
    backgroundColor: "typography.subText",
    left: "".concat(tick.percent, "%")
  }), ___EmotionJSX(Box, {
    position: "absolute",
    marginTop: "22px",
    fontSize: "0.75rem",
    textAlign: "center",
    marginLeft: "".concat(-(100 / count) / 2, "%"),
    width: "".concat(100 / count, "%"),
    left: "".concat(tick.percent, "%")
  }, unitType && unitType, tick.value));
}