import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

import React, { useCallback, useRef, useState } from "react";
import { styled, themeGet } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { ArrowLeftIcon, ArrowRightIcon } from "../Icon";
import { jsx as ___EmotionJSX } from "@emotion/react";
var NavContainer = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &::-webkit-scrollbar {\n    background: transparent; /* Chrome/Safari/Webkit */\n    width: 0px;\n    height: 0px;\n  }\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n"])));
var ScrollIndicator = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 36px;\n  position: absolute;\n  right: ", ";\n  left: ", ";\n  top: 0;\n  bottom: 0;\n  align-items: center;\n  justify-content: ", ";\n  transition: left 0.3s ease-out, right 0.3s ease-out;\n"])), function (props) {
  return props.placement === "right" ? props.visible ? 0 : "-50px" : "auto";
}, function (props) {
  return props.placement === "left" ? props.visible ? 0 : "-50px" : "auto";
}, function (props) {
  return props.placement === "right" ? "flex-end" : "flex-start";
});
var NavArrowButton = styled(Flex)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  justify-content: center;\n  align-items: center;\n  width: 36px;\n  height: 46px;\n  border: 1px solid ", ";\n  border-radius: 4px;\n  background-color: white;\n  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);\n"])), function (props) {
  return themeGet("colors.ui.borderColor")(props);
});
export var scrollTo = function scrollTo(_ref) {
  var scrollerElement = _ref.scrollerElement,
      scrollLeftPosition = _ref.scrollLeftPosition,
      _ref$smoothScroll = _ref.smoothScroll,
      smoothScroll = _ref$smoothScroll === void 0 ? true : _ref$smoothScroll;

  if (scrollerElement) {
    if (smoothScroll) {
      scrollerElement.scroll({
        top: 0,
        left: scrollLeftPosition,
        behavior: "smooth"
      });
    } else {
      /* eslint-disable no-param-reassign */
      scrollerElement.scrollLeft = scrollLeftPosition;
    }
  }
};
export var HorizontalNav = function HorizontalNav(_ref2) {
  var _ref2$scrollIncrement = _ref2.scrollIncrement,
      scrollIncrement = _ref2$scrollIncrement === void 0 ? 3 : _ref2$scrollIncrement,
      _ref2$navItems = _ref2.navItems,
      navItems = _ref2$navItems === void 0 ? [] : _ref2$navItems,
      label = _ref2.label;

  var _useState = useState(false),
      leftArrowVisible = _useState[0],
      setLeftArrowVisible = _useState[1];

  var _useState2 = useState(false),
      rightArrowVisible = _useState2[0],
      setRightArrowVisible = _useState2[1];

  var scrollerRef = useRef(null); // px value used to tell when the arrows are shown/hidden

  var scrollBuffer = 30;
  var handleArrowClick = useCallback(function (side) {
    if (scrollerRef.current) {
      var scrollDistance = side === "next" ? scrollerRef.current.scrollLeft + scrollerRef.current.clientWidth / scrollIncrement : scrollerRef.current.scrollLeft - scrollerRef.current.clientWidth / scrollIncrement;
      scrollTo({
        scrollerElement: scrollerRef.current,
        scrollLeftPosition: scrollDistance
      });
    }
  }, [scrollIncrement]);

  var checkScrollPosition = function checkScrollPosition(scroller) {
    if (scroller.scrollLeft + scroller.clientWidth >= scroller.scrollWidth - scrollBuffer) {
      setRightArrowVisible(false);
    } else {
      setRightArrowVisible(true);
    }

    if (scroller.scrollLeft > scrollBuffer) {
      setLeftArrowVisible(true);
    } else {
      setLeftArrowVisible(false);
    }
  };

  var onRefAssign = function onRefAssign(scrollerElement) {
    scrollerRef.current = scrollerElement;

    if (scrollerElement) {
      var showNavArrows = function showNavArrows() {
        return checkScrollPosition(scrollerElement);
      };

      showNavArrows();
      window.addEventListener("resize", showNavArrows);
    }
  };

  return ___EmotionJSX(Box, {
    "aria-label": label || "Horizontal navigation",
    as: "nav",
    display: "flex",
    height: "50px",
    color: "text",
    position: "relative",
    overflow: "hidden",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center"
  }, ___EmotionJSX(ScrollIndicator, {
    role: "button",
    "aria-label": "Scroll left",
    placement: "left",
    visible: leftArrowVisible,
    zIndex: 2
  }, ___EmotionJSX(NavArrowButton, {
    onClick: function onClick() {
      return handleArrowClick("prev");
    }
  }, ___EmotionJSX(ArrowLeftIcon, null))), ___EmotionJSX(ScrollIndicator, {
    role: "button",
    "aria-label": "Scroll right",
    placement: "right",
    visible: rightArrowVisible,
    zIndex: 2
  }, ___EmotionJSX(NavArrowButton, {
    onClick: function onClick() {
      return handleArrowClick("next");
    }
  }, ___EmotionJSX(ArrowRightIcon, null))), ___EmotionJSX(NavContainer, {
    flex: "1",
    flexWrap: "nowrap",
    overflowX: "auto",
    ref: onRefAssign,
    onScroll: function onScroll(e) {
      return checkScrollPosition(e.currentTarget);
    },
    role: "menubar",
    height: "calc(100% + 20px)",
    pb: "20px"
  }, navItems.map(function (NavItem, index) {
    return ___EmotionJSX(Flex, {
      alignItems: "center",
      key: "nav-item-".concat(index)
    }, NavItem);
  })));
};
HorizontalNav.displayName = "HorizontalNav";
export default HorizontalNav;