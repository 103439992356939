var getFacetHardCodedCols = function getFacetHardCodedCols(facet) {
  if (/Size/.test(facet.name) || facet.name === "Colour") {
    return 2;
  }

  if (facet.name === "Brand" || facet.name === "Category" || facet.name === "Rating") {
    return 1;
  }

  return 0;
};

export default getFacetHardCodedCols;