import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "isExpanded"];
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchFacetsBody = function SearchFacetsBody(_ref) {
  var children = _ref.children,
      isExpanded = _ref.isExpanded,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Box, _extends({
    overflow: "hidden",
    position: "relative",
    height: isExpanded ? "auto" : 0,
    pb: isExpanded ? "10px" : 0
  }, props), children);
};