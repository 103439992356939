import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["containerProps", "prefixIcon", "prefixIconColour", "iconRatio", "disabled", "dropDownIconHideProps", "children", "customSelectItems", "defaultSelectedIndex", "menuProps", "hideSelectedOnDropdown"];

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import React, { useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { border, color, compose, focusBorderColorProp, fontSize, hoverColorProp, layout, space, styled, themeGet } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Hide } from "../Hide";
import { IconDownArrow } from "../Icon";
import { listStyleProps } from "../List";
import { jsx as ___EmotionJSX } from "@emotion/react";
var styleProps = compose(layout, border, space, color, fontSize);
var BaseCustomSelect = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-right: ", ";\n  text-align: left;\n\n  &:hover:enabled {\n    ", "\n    ~ span > svg {\n      ", "\n    }\n  }\n\n  &:focus {\n    ", ";\n  }\n\n  ", "\n\n  cursor: pointer;\n  ", "\n\n  ", "\n"])), themeGet("space.5"), hoverColorProp, hoverColorProp, focusBorderColorProp, function (prop) {
  return prop.maxWidth && "\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;";
}, function (prop) {
  return prop.disabled && "cursor: not-allowed;";
}, styleProps);
var CustomSelectMenu = styled.ul(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  top: calc(100% - 1px);\n  left: 0;\n  right: 0;\n  border: solid 1px ", ";\n  border-radius: 0 0 4px 4px;\n  z-index: 2;\n  background-color: white;\n  ", "\n"])), themeGet("colors.ui.borderColor"), listStyleProps);
var CustomSelectItem = styled.li(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin: 0;\n"])));
BaseCustomSelect.defaultProps = {
  py: 5,
  border: 1,
  borderColor: "ui.borderColor",
  focusBorderColor: "secondary"
};
var IconWrapperAfter = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: calc(50% - 0.75em);\n  right: 8px;\n  padding-right: 10px;\n  pointer-events: none;\n"])));
var IconWrapperBefore = styled.span(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: calc(50% - 0.75em);\n  left: 0.75em;\n"])));
export var CustomSelect = function CustomSelect(_ref) {
  var containerProps = _ref.containerProps,
      PrefixIcon = _ref.prefixIcon,
      prefixIconColour = _ref.prefixIconColour,
      iconRatio = _ref.iconRatio,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      dropDownIconHideProps = _ref.dropDownIconHideProps,
      children = _ref.children,
      customSelectItems = _ref.customSelectItems,
      defaultSelectedIndex = _ref.defaultSelectedIndex,
      menuProps = _ref.menuProps,
      hideSelectedOnDropdown = _ref.hideSelectedOnDropdown,
      otherProps = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(false),
      dropdownOpen = _useState[0],
      setDropdownOpen = _useState[1];

  var _useState2 = useState(defaultSelectedIndex),
      selectedIndex = _useState2[0],
      setSelectedIndex = _useState2[1];

  useEffect(function () {
    setSelectedIndex(defaultSelectedIndex);
  }, [defaultSelectedIndex]);
  var optionsRef = useRef([]);

  function triggerAction(item, index) {
    if (item.disabled) return;

    if (item.onSelect) {
      setSelectedIndex(index);
      item.onSelect();
    }

    setDropdownOpen(false);
  }

  function handleKeyEvents(event, item, index) {
    if (event.key === "Enter") {
      triggerAction(item, index);
    }

    if (event.key === "ArrowDown" && optionsRef.current[index + 1]) {
      var _optionsRef$current;

      (_optionsRef$current = optionsRef.current[index + 1]) === null || _optionsRef$current === void 0 ? void 0 : _optionsRef$current.focus();
    }

    if (event.key === "ArrowUp" && optionsRef.current[index - 1]) {
      var _optionsRef$current2;

      (_optionsRef$current2 = optionsRef.current[index - 1]) === null || _optionsRef$current2 === void 0 ? void 0 : _optionsRef$current2.focus();
    }
  }

  return ___EmotionJSX(Box, _extends({
    position: "relative",
    alignItems: "center"
  }, containerProps), ___EmotionJSX(OutsideClickHandler, {
    onOutsideClick: function onOutsideClick() {
      return setDropdownOpen(false);
    }
  }, ___EmotionJSX(BaseCustomSelect, _extends({
    "aria-disabled": disabled,
    disabled: disabled,
    type: "button",
    "aria-haspopup": "listbox",
    width: containerProps !== null && containerProps !== void 0 && containerProps.width ? "100%" : "auto",
    pl: PrefixIcon ? "2.5rem" : "1.25rem",
    borderRadius: dropdownOpen ? "4px 4px 0 0" : "4px",
    pr: "45px",
    color: disabled ? "typography.inactive" : "typography.text",
    bg: "white",
    tabIndex: 0,
    onFocus: function onFocus() {
      return setDropdownOpen(true);
    },
    onMouseDown: function onMouseDown(e) {
      setDropdownOpen(!dropdownOpen);
      e.stopPropagation();
    },
    onKeyDown: function onKeyDown(e) {
      if (e.key === "ArrowDown" && optionsRef.current[0]) {
        var _optionsRef$current$;

        (_optionsRef$current$ = optionsRef.current[0]) === null || _optionsRef$current$ === void 0 ? void 0 : _optionsRef$current$.focus();
      }
    }
  }, otherProps), dropdownOpen == true && hideSelectedOnDropdown == true ? "" : selectedIndex !== undefined && selectedIndex >= 0 ? customSelectItems[selectedIndex].label : children), ___EmotionJSX(Hide, dropDownIconHideProps, ___EmotionJSX(IconWrapperAfter, null, ___EmotionJSX(IconDownArrow, {
    width: "0.8em",
    height: "0.4em",
    color: disabled ? "typography.inactive" : "inherit",
    title: "select drop down arrow"
  }))), PrefixIcon && ___EmotionJSX(IconWrapperBefore, null, ___EmotionJSX(PrefixIcon, {
    height: "1.5em",
    width: "".concat(1.5 * (iconRatio || 0.5), "em"),
    color: disabled ? "typography.inactive" : prefixIconColour || "inherit"
  })), dropdownOpen && ___EmotionJSX(CustomSelectMenu, _extends({
    role: "listbox"
  }, menuProps), customSelectItems.map(function (item, index) {
    return ___EmotionJSX(CustomSelectItem, {
      ref: function ref(elem) {
        return optionsRef.current[index] = elem;
      },
      id: item.label,
      role: "option",
      "aria-selected": index === selectedIndex,
      key: index,
      tabIndex: 0,
      onClick: function onClick() {
        return triggerAction(item, index);
      },
      onKeyDown: function onKeyDown(e) {
        return handleKeyEvents(e, item, index);
      }
    }, item.component);
  }))));
};
CustomSelect.displayName = "CustomSelect";
CustomSelect.defaultProps = {
  containerProps: {
    color: "typography.text"
  },
  dropDownIconHideProps: {}
};
export default CustomSelect;