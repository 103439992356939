import React from "react";
import { emitTacticalCategory, useTriggerImpression } from "@catchoftheday/analytics";
import { Box, Flex, Heading, Image } from "@catchoftheday/cg-components";
import { useIsInViewport } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TitleBanner = function TitleBanner(_ref) {
  var tacticalCategory = _ref.tacticalCategory;
  var name = tacticalCategory.name,
      subtitle = tacticalCategory.subtitle,
      _tacticalCategory$the = tacticalCategory.themeColor,
      themeColor = _tacticalCategory$the === void 0 ? "transparent" : _tacticalCategory$the,
      titleImageUrl = tacticalCategory.titleImageUrl;

  var _useIsInViewport = useIsInViewport(),
      ref = _useIsInViewport.ref,
      inView = _useIsInViewport.inView;

  useTriggerImpression({
    emitFn: function emitFn() {
      emitTacticalCategory({
        action: "impression",
        label: "title-banner"
      });
    },
    inView: inView
  });
  return ___EmotionJSX(Flex, {
    ref: ref,
    px: {
      xs: 6,
      lg: 12
    },
    width: "100%",
    borderRadius: "semi",
    overflow: "hidden",
    backgroundColor: themeColor,
    gap: {
      xs: 6,
      lg: 12
    },
    flexDirection: {
      xs: "column",
      md: "row"
    },
    alignItems: "center"
  }, ___EmotionJSX(Box, {
    py: {
      xs: 6,
      lg: 12
    },
    width: {
      xs: "100%",
      md: "50%"
    },
    maxWidth: "500px"
  }, ___EmotionJSX(Heading.h1, {
    fontSize: {
      xs: "3xl",
      lg: "5xl"
    },
    fontWeight: "bold",
    mt: 0,
    mb: {
      xs: 2,
      lg: 3
    }
  }, name), ___EmotionJSX(Heading.h2, {
    fontSize: {
      xs: "base",
      lg: "2xl"
    },
    fontWeight: "normal",
    m: 0
  }, subtitle)), titleImageUrl && ___EmotionJSX(Flex, {
    flexGrow: 1,
    alignItems: "center"
  }, ___EmotionJSX(Image, {
    display: "block",
    mx: "auto",
    src: titleImageUrl,
    height: "auto",
    width: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    alt: "Products image of ".concat(name, " category")
  })));
};