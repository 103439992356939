import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Flex, Heading, Text } from "@catchoftheday/cg-components";
import { SELLER_COMPLIANCE_NOTE } from "@search-app/consts";
import { useShallowSelector } from "@search-app/store";
import { hasSearchCorrection, hasSearchHits, hasSearchSortOptions, pageHeading, searchContext, searchHits, searchQuery } from "@search-app/store/search/selectors";
import { HeaderContentPlaceholder } from "../HeaderContentPlaceholder";
import { NoResultsHeader } from "../NoResultsHeader";
import { FeaturedLink } from "./FeaturedLink";
import { SearchSortOptions } from "./SearchSortOptions";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchHeaderInternal = function SearchHeaderInternal(_ref) {
  var pageHeading = _ref.pageHeading,
      searchContext = _ref.searchContext,
      _ref$hasSearchCorrect = _ref.hasSearchCorrection,
      hasSearchCorrection = _ref$hasSearchCorrect === void 0 ? false : _ref$hasSearchCorrect,
      _ref$hasSearchHits = _ref.hasSearchHits,
      hasSearchHits = _ref$hasSearchHits === void 0 ? false : _ref$hasSearchHits,
      _ref$hasSearchSortOpt = _ref.hasSearchSortOptions,
      hasSearchSortOptions = _ref$hasSearchSortOpt === void 0 ? false : _ref$hasSearchSortOpt,
      isMobile = _ref.isMobile,
      searchQuery = _ref.searchQuery,
      searchHits = _ref.searchHits;

  if (!searchQuery) {
    return ___EmotionJSX(HeaderContentPlaceholder, null);
  }

  if (!hasSearchHits) {
    return ___EmotionJSX(NoResultsHeader, {
      searchQuery: pageHeading || searchQuery
    });
  }

  var searchHitsCount = searchHits || 0;
  var formattedSearchHits = searchHitsCount.toLocaleString("en-AU");
  var resultString = "result".concat(searchHitsCount > 1 ? "s" : "");
  var isSellerPage = searchContext === "seller";
  var searchResultsTitle = "".concat(formattedSearchHits, " ").concat(resultString, " for \"").concat(searchQuery, "\"");

  if (hasSearchCorrection) {
    searchResultsTitle += ". ";
  }

  if (pageHeading) {
    searchResultsTitle = "".concat(pageHeading).concat(isSellerPage ? "*" : "");
  }

  return ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    mb: 4,
    mt: {
      xs: 1,
      md: 0
    },
    width: "100%",
    alignItems: "center"
  }, ___EmotionJSX(Box, null, ___EmotionJSX(Heading.h1, {
    my: 0,
    pl: {
      md: "20px",
      lg: 0
    },
    fontSize: {
      xs: "lg",
      md: "2xl",
      lg: "3xl"
    }
  }, searchResultsTitle), isSellerPage && ___EmotionJSX(Text, {
    color: "textTertiary",
    fontSize: "xs",
    pl: {
      md: "20px",
      lg: 0
    },
    m: 0
  }, SELLER_COMPLIANCE_NOTE), searchQuery && ___EmotionJSX(FeaturedLink, {
    searchQuery: searchQuery
  })), !isMobile && hasSearchSortOptions && ___EmotionJSX(Box, {
    paddingRight: "20px"
  }, ___EmotionJSX(SearchSortOptions, null)));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      pageHeading: pageHeading(state),
      searchContext: searchContext(state),
      hasSearchCorrection: hasSearchCorrection(state),
      hasSearchHits: hasSearchHits(state),
      hasSearchSortOptions: hasSearchSortOptions(state),
      searchQuery: searchQuery(state),
      searchHits: searchHits(state)
    };
  });
};
/**
 * For external API props being passed into the component
 */


export var SearchHeader = function SearchHeader(props) {
  var state = useReduxState();
  return ___EmotionJSX(SearchHeaderInternal, _extends({}, state, props));
};