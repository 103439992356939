import React, { useCallback } from "react";
import { emitTacticalCategory, useTriggerImpression } from "@catchoftheday/analytics";
import { Box } from "@catchoftheday/cg-components";
import { useIsInViewport } from "@catchoftheday/utilities";
import { SectionHeading } from "../SectionHeading";
import { EventBanner } from "./EventBanner";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FeaturedEvents = function FeaturedEvents(_ref) {
  var featuredEventIDs = _ref.featuredEventIDs;
  var trackEventClicks = useCallback(function (eventId) {
    emitTacticalCategory({
      action: "click",
      label: "featured-events",
      property: eventId.toString()
    });
  }, []);

  var _useIsInViewport = useIsInViewport(),
      ref = _useIsInViewport.ref,
      inView = _useIsInViewport.inView;

  useTriggerImpression({
    emitFn: function emitFn() {
      emitTacticalCategory({
        action: "impression",
        label: "featured-events"
      });
    },
    inView: inView
  });
  return ___EmotionJSX(Box, {
    ref: ref
  }, ___EmotionJSX(SectionHeading, {
    title: "Don't miss these deals"
  }), ___EmotionJSX(Box, {
    display: "grid",
    gridTemplateColumns: {
      xs: "repeat(1, 1fr)",
      md: "repeat(2, 1fr)"
    },
    gap: 4
  }, featuredEventIDs.map(function (eventId) {
    return ___EmotionJSX(EventBanner, {
      onClick: function onClick() {
        return trackEventClicks(eventId);
      },
      key: eventId,
      eventId: eventId
    });
  })));
};