import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { css } from "@catchoftheday/theme";
import { useSelector } from "@search-app/store";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var AdultBlurContainer = function AdultBlurContainer(_ref) {
  var children = _ref.children;
  var open = useSelector(function (state) {
    return state.adultModal.open;
  });
  return ___EmotionJSX(Box, {
    css: open ? css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n              filter: blur(20px);\n            "]))) : undefined
  }, children);
};