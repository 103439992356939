import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isDisabled", "onPageClick", "page", "pageCurrent", "pageTotal", "parameter", "urlPath", "text", "fontSize"];
import React from "react";
import { Button, LinkButton } from "../Button";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PaginationItem = function PaginationItem(_ref) {
  var isDisabled = _ref.isDisabled,
      onPageClick = _ref.onPageClick,
      page = _ref.page,
      pageCurrent = _ref.pageCurrent,
      pageTotal = _ref.pageTotal,
      _ref$parameter = _ref.parameter,
      parameter = _ref$parameter === void 0 ? "p" : _ref$parameter,
      urlPath = _ref.urlPath,
      text = _ref.text,
      fontSize = _ref.fontSize,
      other = _objectWithoutProperties(_ref, _excluded);

  var itemLabel = text || page;
  var itemActive = page === pageCurrent;
  var itemDisabled = page < 1 || page > pageTotal || isDisabled;
  var customStyles = {
    outline: true,
    borderColor: "ui.borderColor",
    borderWidth: itemActive ? 0 : "1px",
    color: itemActive ? "white" : "typography.text",
    backgroundColor: itemActive ? "typography.text" : "white",
    hoverBorderColor: itemActive ? "typography.text" : "#c4c4c4",
    borderRadius: "none",
    fontSize: fontSize
  };

  if (!urlPath && typeof onPageClick === "function") {
    return ___EmotionJSX(Button, _extends({
      sizeVariant: "xs",
      variant: "plain",
      disabled: itemDisabled,
      onClick: function onClick() {
        return onPageClick(page);
      },
      m: "4px"
    }, customStyles, other), itemLabel);
  }

  if (urlPath) {
    var paginationUrl = new URL(urlPath);
    var params = paginationUrl.searchParams;
    params.set(parameter, page.toString());
    paginationUrl.search = params.toString();
    return ___EmotionJSX(LinkButton, _extends({
      m: "4px",
      block: false,
      sizeVariant: "xs",
      variant: "plain",
      href: paginationUrl.toString(),
      disabled: itemDisabled,
      hoverColor: itemActive ? "white" : "typography.text",
      onClick: function onClick(event) {
        if (typeof onPageClick === "function") {
          event.preventDefault();

          if (!itemDisabled) {
            onPageClick(page);
          }
        }
      }
    }, customStyles, other), itemLabel);
  }

  return null;
};