import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled, theme } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Input } from "../Input";
import { Label } from "../Label";
import { Span } from "../Text";
import { jsx as ___EmotionJSX } from "@emotion/react";
var RangeInputWrap = styled(Label)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  margin-bottom: 0;\n  font-weight: 400;\n  width: auto;\n  min-width: 100px;\n  > span {\n    position: absolute;\n    left: 0.5rem;\n    top: 50%;\n    margin-top: -10px;\n    height: 20px;\n    font-weight: 400;\n  }\n"])));
var LogRangeSliderWrapper = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  user-select: none;\n"])));
var CustomSlider = styled(Slider)({
  "& .MuiSlider-thumb": {
    height: 32,
    width: 32,
    pointerEvents: "auto!important",
    "&::after": {
      width: 48,
      height: 48
    }
  }
});
var customTheme = createTheme({
  palette: {
    primary: {
      main: theme.colors.brand.primary
    }
  }
});
export var LogRangeSlider = function LogRangeSlider(_ref) {
  var defaultValues = _ref.defaultValues,
      minMaxValues = _ref.minMaxValues,
      onChange = _ref.onChange,
      inputProps = _ref.inputProps,
      disabled = _ref.disabled,
      containerProps = _ref.containerProps,
      rangeContainerProps = _ref.rangeContainerProps,
      _ref$step = _ref.step,
      step = _ref$step === void 0 ? 1 : _ref$step,
      _ref$unitType = _ref.unitType,
      unitType = _ref$unitType === void 0 ? "$" : _ref$unitType,
      _ref$showInputs = _ref.showInputs,
      showInputs = _ref$showInputs === void 0 ? true : _ref$showInputs,
      displayUnitMin = _ref.displayUnitMin,
      displayUnitMax = _ref.displayUnitMax;
  var _ref2 = [minMaxValues[0] || 1, minMaxValues[1]],
      min = _ref2[0],
      max = _ref2[1];

  var _useState = useState(Math.log10(min)),
      minValue = _useState[0],
      setMinValue = _useState[1];

  var _useState2 = useState(Math.log10(max)),
      maxValue = _useState2[0],
      setMaxValue = _useState2[1];

  var _useState3 = useState((maxValue - minValue) / (max - min)),
      scale = _useState3[0],
      setScale = _useState3[1];

  var _useState4 = useState([]),
      lastValue = _useState4[0],
      setLastValue = _useState4[1];

  var _useState5 = useState(_toConsumableArray(defaultValues)),
      values = _useState5[0],
      setValues = _useState5[1];

  var _useState6 = useState(_toConsumableArray(defaultValues)),
      update = _useState6[0],
      setUpdate = _useState6[1];

  var scaleFunc = function scaleFunc(value) {
    return Math.round(Math.pow(10, (value - min) * scale + minValue));
  };

  var inverselogScale = function inverselogScale(val, minValue, scale, min) {
    var foo = Math.round((Math.log10(val) - minValue) / scale + min);
    return foo;
  };

  useEffect(function () {
    var _ref3 = [minMaxValues[0] || 1, minMaxValues[1]],
        min = _ref3[0],
        max = _ref3[1];
    var scale = (Math.log10(max) - Math.log10(min)) / (max - min);
    var minVal = Math.log10(min);
    var maxVal = Math.log10(max);
    setMinValue(minVal);
    setMaxValue(maxVal);
    setScale(scale);
    setValues([inverselogScale(defaultValues[0], minVal, scale, min), inverselogScale(defaultValues[1], minVal, scale, min)]);
    setUpdate(defaultValues);
  }, [minMaxValues, defaultValues]);

  var updateValues = function updateValues(e) {
    var updateOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var input = e.target;
    var value = input.value ? Number(input.value) : -1;
    var index = input.name === "minValue" ? 0 : 1;

    if (!updateOnly && index === 0) {
      if (value >= update[1]) {
        value = update[1] - step;
      }

      if (value < min) {
        value = min;
      }
    }

    if (!updateOnly && index === 1) {
      if (value <= update[0]) {
        value = update[0] + step;
      }

      if (value > max) {
        value = max;
      }
    }

    var updatedValues = _toConsumableArray(update);

    updatedValues[index] = value;
    setUpdate(updatedValues);

    if (!updateOnly) {
      setValues([inverselogScale(updatedValues[0], minValue, scale, min), inverselogScale(updatedValues[1], minValue, scale, min)]);
      onChange(updatedValues);
    }
  };

  var onInputChange = function onInputChange(e) {
    updateValues(e, true);
  };

  var keyChange = function keyChange(e) {
    if (e.key === "Enter") {
      updateValues(e);
    }
  };

  var blurChange = function blurChange(e) {
    updateValues(e);
  };

  var onRangeChange = function onRangeChange(_event, newValues) {
    setValues(newValues);
    setUpdate([scaleFunc(newValues[0]), scaleFunc(newValues[1])]);
  };

  var onCommitedChange = function onCommitedChange() {
    var unchanged = update.every(function (value, index) {
      return value === lastValue[index];
    });
    if (unchanged) return;
    onChange(update);
    setLastValue(update);
  };

  var commonInputProps = {
    onChange: onInputChange,
    onKeyDown: keyChange,
    onBlur: blurChange,
    disabled: disabled,
    type: "number",
    min: min,
    max: max,
    p: "0.5rem 0.5rem 0.5rem 2rem"
  };
  var marks = [{
    id: "1",
    label: displayUnitMin || "Min ".concat(unitType).concat(minMaxValues[0])
  }, {
    id: "2",
    label: displayUnitMax || "Max ".concat(unitType).concat(minMaxValues[1])
  }];
  return ___EmotionJSX(LogRangeSliderWrapper, _extends({
    as: "section",
    flexDirection: "column",
    width: "100%"
  }, containerProps), showInputs && ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    mb: 5
  }, ___EmotionJSX(RangeInputWrap, {
    id: "price-slider-min-value",
    "aria-label": "Minimum value"
  }, ___EmotionJSX(Span, null, unitType), ___EmotionJSX(Input, _extends({
    value: update[0] === -1 ? "" : update[0],
    name: "minValue"
  }, commonInputProps, inputProps))), ___EmotionJSX(RangeInputWrap, {
    id: "price-slider-max-value",
    "aria-label": "Maximum value"
  }, ___EmotionJSX(Span, null, unitType), ___EmotionJSX(Input, _extends({
    value: update[1] === -1 ? "" : update[1],
    name: "maxValue"
  }, commonInputProps, inputProps)))), ___EmotionJSX(Box, _extends({
    px: "12px",
    pb: "6px"
  }, rangeContainerProps), ___EmotionJSX(ThemeProvider, {
    theme: customTheme
  }, ___EmotionJSX(CustomSlider, {
    value: values,
    onChange: onRangeChange,
    onChangeCommitted: onCommitedChange,
    step: step,
    min: min,
    max: max,
    scale: scaleFunc,
    disableSwap: true,
    disabled: disabled
  }))), ___EmotionJSX(Flex, {
    justifyContent: "space-between"
  }, marks.map(function (_ref4) {
    var id = _ref4.id,
        label = _ref4.label;
    return ___EmotionJSX(Span, {
      key: id,
      fontSize: "xs"
    }, label);
  })));
};
LogRangeSlider.displayName = "LogRangeSlider";
export default LogRangeSlider;