import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Button, IconDownArrow, Span } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { FacetCount, FacetListItem } from "../../SearchFacets.styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
var IconLeftArrow = styled(IconDownArrow)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transform: rotate(90deg);\n  width: 8px;\n  height: 8px;\n  margin-right: 5px;\n  margin-top: -2px;\n"])));
export var SearchFacetsNestedItem = function SearchFacetsNestedItem(_ref) {
  var count = _ref.count,
      name = _ref.name,
      onClick = _ref.onClick,
      selected = _ref.selected;
  return ___EmotionJSX(FacetListItem, {
    padding: "10px 16px"
  }, ___EmotionJSX(Button, {
    onClick: onClick,
    variant: "plain",
    sizeVariant: "xs",
    color: selected ? "secondary" : "inherit",
    fontSize: "0.875rem",
    fontWeight: "100",
    padding: "0",
    border: "none",
    whiteSpace: "normal",
    textAlign: "left"
  }, selected && ___EmotionJSX(IconLeftArrow, null), ___EmotionJSX(Span, null, name, count && ___EmotionJSX(FacetCount, null, "(".concat(count, ")")))));
};