import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { AppExperimentContext, mapSponsoredProductsTracking, SNOWPLOW_SESSION_ID, SOURCE_TYPE_SEARCH_NO_RESULTS, SOURCE_TYPE_SEARCH_TOP_SPONSORED } from "@catchoftheday/analytics";
import { Box, Flex, Heading, Pagination } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { getEnvConfig } from "@catchoftheday/env-config";
import { SearchTermRecommendedProducts } from "@catchoftheday/personalisation";
import { convertProductCard } from "@catchoftheday/product-components";
import { getSalesChannel, parseQueryString } from "@catchoftheday/utilities";
import { useUpdateDataLayer, useUpdateEcommerceData } from "@search-app/analytics";
import { CategoryDescription } from "@search-app/components/CategoryDescription";
import { ProductGrid } from "@search-app/components/ProductGrid";
import { TacticalCategoryContext } from "@search-app/contexts/TacticalCategoryContext";
import { useRetailMediaProductsQuery } from "@search-app/generated/graphql";
import { SEARCH_URL_PARAM_PAGE } from "@search-app/helpers/search/search.helpers.params";
import { useShallowSelector } from "@search-app/store";
import { hasFacets, isInitialSearch, isSearchLoading, pageCurrent, pageTotal, searchPageLimit, searchResults } from "@search-app/store/search/selectors";
import { hasUnsupportedSponsoredFacets, mapSponsoredFacets, routeToQueryParam } from "@search-app/utilities";
import scrollTop from "@search-app/utilities/scrollTop.utilities";
import { CategoryNav, NoResultsFooter, TrendingSearches } from "../NoResults";
import { TopSellersRecommender } from "../NoResults/TopSellersRecommender";
import { ResultsPlaceholder } from "./ResultsPlaceholder";
import { jsx as ___EmotionJSX } from "@emotion/react";
var noResultsSourceType = SOURCE_TYPE_SEARCH_NO_RESULTS;
export var Results = function Results(_ref) {
  var isMobile = _ref.isMobile,
      host = _ref.host;
  useUpdateAnalytics();

  var _useReduxState = useReduxState(),
      isInitialSearch = _useReduxState.isInitialSearch,
      hasFacets = _useReduxState.hasFacets,
      searchResults = _useReduxState.searchResults,
      isLoading = _useReduxState.isLoading,
      pageCurrent = _useReduxState.pageCurrent,
      pageTotal = _useReduxState.pageTotal,
      searchPageLimit = _useReduxState.searchPageLimit;

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data,
      customerLoading = _useContext.loading;

  var _useState = useState(),
      snowplowSessionId = _useState[0],
      setSnowplowSessionId = _useState[1];

  var _useState2 = useState(),
      searchTerm = _useState2[0],
      setSearchTerm = _useState2[1];

  var _useState3 = useState(),
      sponsoredItems = _useState3[0],
      setSponsoredItems = _useState3[1];

  var _useState4 = useState(),
      sponsoredItemsTracking = _useState4[0],
      setSponsoredItemsTracking = _useState4[1];

  var _useState5 = useState(null),
      appliedSponsoredFacets = _useState5[0],
      setAppliedSponsoredFacets = _useState5[1];

  var _useState6 = useState(false),
      hideSponsored = _useState6[0],
      setHideSponsored = _useState6[1];

  var isCOTD = !!host && getSalesChannel(host) === "COTD";
  var NUM_SPONSORED_RESULTS_DESKTOP = getEnvConfig().SEARCH_SPONSORED_NUM_RESULTS_DESKTOP || 4;
  var NUM_SPONSORED_RESULTS_MOBILE = getEnvConfig().SEARCH_SPONSORED_NUM_RESULTS_MOBILE || 2;
  var sponsoredItemsLimit = isMobile ? Number(NUM_SPONSORED_RESULTS_MOBILE) : Number(NUM_SPONSORED_RESULTS_DESKTOP);

  var _useContext2 = useContext(AppExperimentContext),
      getExperiments = _useContext2.getExperiments;

  var _getExperiments = getExperiments(["retail_media_search_results_top_oct_23"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      retailMediaExperiment = _getExperiments$exper[0];

  useEffect(function () {
    var spId = sessionStorage.getItem(SNOWPLOW_SESSION_ID);

    if (spId) {
      setSnowplowSessionId(spId);
    } else {
      setSnowplowSessionId(nanoid());
    }

    var updateSponsoredFacets = function updateSponsoredFacets() {
      var queryObject = parseQueryString(window.location.search);
      var sponsoredFacets = mapSponsoredFacets(queryObject);
      setHideSponsored(!!queryObject["sort"] && queryObject["sort"] !== "default" || hasUnsupportedSponsoredFacets(queryObject));
      setSearchTerm(queryObject["query"]);
      setAppliedSponsoredFacets(Object.keys(sponsoredFacets).length > 0 ? sponsoredFacets : null);
    };

    updateSponsoredFacets();
    addEventListener("popstate", updateSponsoredFacets);
    return function () {
      removeEventListener("popstate", updateSponsoredFacets);
    };
  }, []);

  var _useRetailMediaProduc = useRetailMediaProductsQuery({
    variables: {
      limit: sponsoredItemsLimit,
      inventoryId: "search_results_top",
      snowplowSessionId: snowplowSessionId,
      customerUUID: customerData === null || customerData === void 0 ? void 0 : customerData.metaData.customerUuid,
      searchQuery: searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.replace(/\+/g, " "),
      facets: appliedSponsoredFacets
    },
    skip: !isCOTD || customerLoading || !searchTerm || !snowplowSessionId || !(retailMediaExperiment !== null && retailMediaExperiment !== void 0 && retailMediaExperiment.bucket) || (pageCurrent || 1) > 1 || hideSponsored
  }),
      data = _useRetailMediaProduc.data,
      loading = _useRetailMediaProduc.loading;

  useEffect(function () {
    var sponsoredItems = data === null || data === void 0 ? void 0 : data.retailMediaProducts.products.map(function (product) {
      return _objectSpread(_objectSpread({}, convertProductCard(product, product.selectedOffer)), {}, {
        sourceType: SOURCE_TYPE_SEARCH_TOP_SPONSORED,
        sourceId: searchTerm,
        isSponsored: true,
        linkToOffer: true
      });
    });
    var sponsoredItemsTracking = mapSponsoredProductsTracking(data === null || data === void 0 ? void 0 : data.retailMediaProducts.tracking);

    if (!isLoading) {
      setSponsoredItems(sponsoredItems);
      setSponsoredItemsTracking(sponsoredItemsTracking);
    }
  }, [data, searchTerm, isLoading]);
  useEffect(function () {
    if (true && window.scrollY !== 0 && searchResults.length < 9) {
      window.scrollTo(0, 0);
    }
  }, [searchResults]); // Tactical Category

  var _useContext3 = useContext(TacticalCategoryContext),
      tacticalCategory = _useContext3.tacticalCategory;

  if (isInitialSearch) {
    return ___EmotionJSX(Flex, {
      flexGrow: 1,
      flexWrap: "wrap"
    }, ___EmotionJSX(ResultsPlaceholder, null));
  }

  if (!searchResults.length) {
    return null;
  }

  if (!hasFacets) {
    return ___EmotionJSX(Box, {
      as: "article",
      mb: 5,
      width: "100%"
    }, ___EmotionJSX(TrendingSearches, null), ___EmotionJSX(CategoryNav, {
      sourceType: noResultsSourceType,
      domain: host
    }), searchTerm && ___EmotionJSX(SearchTermRecommendedProducts, {
      searchTerm: searchTerm,
      sourceId: searchTerm,
      isMobile: isMobile
    }), ___EmotionJSX(Heading.h2, {
      mx: 3,
      fontSize: {
        xs: "lg",
        md: "2xl"
      }
    }, "Have a look at our top sellers"), ___EmotionJSX(TopSellersRecommender, {
      products: searchResults,
      sourceType: noResultsSourceType,
      sourceId: searchTerm,
      isMobile: isMobile
    }), ___EmotionJSX(NoResultsFooter, null));
  }

  return ___EmotionJSX(Box, {
    as: "article",
    mb: 5,
    width: "100%"
  }, ___EmotionJSX(Box, {
    as: "section"
  }, ___EmotionJSX(ProductGrid, {
    lazyLoad: true,
    productItems: [].concat(_toConsumableArray(sponsoredItems || (loading ? Array(sponsoredItemsLimit).fill(null) : [])), _toConsumableArray(searchResults)),
    sponsoredItemsTracking: sponsoredItemsTracking,
    positionOffset: searchPageLimit && pageCurrent ? searchPageLimit * (pageCurrent - 1) + (retailMediaExperiment !== null && retailMediaExperiment !== void 0 && retailMediaExperiment.bucket && pageCurrent > 1 ? sponsoredItemsLimit : 0) : 0
  })), typeof pageCurrent === "number" && typeof pageTotal === "number" && true ? ___EmotionJSX(Flex, {
    justifyContent: "center"
  }, ___EmotionJSX(Pagination, {
    isDisabled: isLoading,
    onPageClick: function onPageClick(page) {
      routeToQueryParam([{
        key: "page",
        value: page
      }]);
      scrollTop();
    },
    urlPath: window.location.href,
    pageCurrent: pageCurrent,
    pageTotal: pageTotal,
    parameter: SEARCH_URL_PARAM_PAGE,
    fontSize: [10, 12, 14]
  })) : null, tacticalCategory && ___EmotionJSX(CategoryDescription, {
    title: tacticalCategory.name,
    description: tacticalCategory.description,
    containerProps: {
      mt: 6
    }
  }));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      isInitialSearch: isInitialSearch(state),
      isLoading: isSearchLoading(state),
      pageCurrent: pageCurrent(state),
      pageTotal: pageTotal(state),
      hasFacets: hasFacets(state),
      searchResults: searchResults(state),
      searchPageLimit: searchPageLimit(state)
    };
  });
};

var useUpdateAnalytics = function useUpdateAnalytics() {
  useUpdateDataLayer();
  useUpdateEcommerceData();
};