import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import React, { useRef } from "react";
import { emitFilterCollapse } from "@catchoftheday/analytics";
import { Button, Flex, IconAlert, IconDownArrow, Label, NotificationBadge, Span, Text } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { useShallowSelector } from "@search-app/store";
import { searchQuery } from "@search-app/store/search/selectors";
import { jsx as ___EmotionJSX } from "@emotion/react";
var FacetsHeader = styled(Label)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  padding: 0 16px;\n  height: 50px;\n  position: relative;\n  margin-bottom: 0;\n  ", "\n"])), function (props) {
  return !props.noncollapsible && "\n  &:hover {\n    color: ".concat(themeGet("colors.secondary")(props), ";\n  }");
});
var ClearButton = styled(Button)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  padding: 0;\n  color: ", ";\n  text-transform: capitalize;\n  right: 80px;\n  top: 0px;\n  height: 50px;\n  width: 50px;\n  opacity: 1;\n  visibility: visible;\n  transition: visibility ", ",\n    opacity ", ";\n  font-size: 0.75rem;\n  ", "\n  ", "\n"])), themeGet("colors.secondary"), themeGet("transitions.default"), themeGet("transitions.default"), function (props) {
  return !props.isExpanded && "\n    opacity: 0;\n    visibility: hidden;\n  ";
}, function (props) {
  return props.noncollapsible && "\n    right: 15px;\n  ";
});
var DownArrow = styled(Span)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  transform-origin: 50% 50%;\n  transition: all ", ";\n  transform: ", ";\n"])), themeGet("transitions.default"), function (props) {
  return props.isExpanded ? "rotate(180deg)" : "rotate(0deg)";
});
var HeaderInput = styled("input")(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: absolute;\n  width: 1px;\n  height: 1px;\n  margin: -1px;\n  padding: 0;\n  overflow: hidden;\n  clip: rect(0, 0, 0, 0);\n  border: 0;\n"])));
var FacetTitle = styled(Span)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-weight: 300;\n  font-size: 1rem;\n  line-height: 1rem;\n  ", "\n"])), function (props) {
  return props.hasSelection && "\n    max-width: 50%;\n    font-weight: 400;\n  ";
});
export var SearchFacetsHeaderInternal = function SearchFacetsHeaderInternal(_ref) {
  var isExpanded = _ref.isExpanded,
      isForSortBy = _ref.isForSortBy,
      selectedLabel = _ref.selectedLabel,
      id = _ref.id,
      title = _ref.title,
      onExpandChange = _ref.onExpandChange,
      onClear = _ref.onClear,
      noncollapsible = _ref.noncollapsible,
      searchTimeout = _ref.searchTimeout,
      hasSearchBar = _ref.hasSearchBar,
      totalSelected = _ref.totalSelected,
      _ref$searchQuery = _ref.searchQuery,
      searchQuery = _ref$searchQuery === void 0 ? "" : _ref$searchQuery;
  var inputEl = useRef(null);
  var inputAttr = {
    id: "".concat(id, "-toggle"),
    type: "checkbox",
    ref: inputEl
  };

  if (isForSortBy) {
    inputAttr.checked = true;
  }

  if (noncollapsible) {
    inputAttr.checked = true;
    inputAttr.disabled = true;
  } else if (onExpandChange) {
    inputAttr.checked = !!isExpanded;

    inputAttr.onChange = function (e) {
      onExpandChange(id, e.target.checked, hasSearchBar ? e.target : undefined);
      emitFilterCollapse({
        action: e.target.checked ? "open-filter" : "close-filter",
        filterName: title,
        searchQuery: searchQuery
      });
    };
  }

  var totalSelectedBadge = !!totalSelected && (totalSelected > 9 ? "9+" : "".concat(totalSelected));
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(HeaderInput, inputAttr), ___EmotionJSX(Flex, {
    flexDirection: "column",
    position: "sticky",
    top: "0",
    style: {
      zIndex: 3
    },
    background: "white"
  }, ___EmotionJSX(FacetsHeader, {
    htmlFor: "".concat(id, "-toggle"),
    noncollapsible: noncollapsible
  }, ___EmotionJSX(FacetTitle, {
    hasSelection: !!selectedLabel && selectedLabel.length > 0
  }, title), ___EmotionJSX(Flex, null, selectedLabel && selectedLabel.length > 0 && onClear && ___EmotionJSX(ClearButton, {
    onClick: function onClick(e) {
      e.preventDefault();
      onClear({
        facet: id
      });
    },
    variant: "plain",
    sizeVariant: "xs",
    isExpanded: isExpanded,
    noncollapsible: noncollapsible
  }, "Clear"), !isForSortBy && selectedLabel && totalSelectedBadge && totalSelectedBadge !== "0" && ___EmotionJSX(NotificationBadge, {
    top: 0,
    absRight: "25px",
    position: "relative",
    backgroundColor: "secondary",
    text: totalSelectedBadge
  }), !noncollapsible && ___EmotionJSX(DownArrow, {
    isExpanded: isExpanded
  }, ___EmotionJSX(IconDownArrow, {
    width: "10px",
    height: "18px",
    title: "Collapse icon"
  })))), selectedLabel && searchTimeout && ___EmotionJSX(Flex, null, ___EmotionJSX(IconAlert, {
    mx: 4,
    color: "error"
  }), ___EmotionJSX(Text, {
    my: 0,
    mr: 4,
    color: "error"
  }, "Oops, an error occured displaying your filter selection. Please try again."))));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      searchQuery: searchQuery(state)
    };
  });
};

export var SearchFacetsHeader = function SearchFacetsHeader(props) {
  var state = useReduxState();
  return ___EmotionJSX(SearchFacetsHeaderInternal, _extends({}, state, props));
};