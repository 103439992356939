import React from "react";
import { Box, Flex, Image, Link, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ThumbnailItem = function ThumbnailItem(_ref) {
  var item = _ref.item,
      onClick = _ref.onClick;
  var id = item.id,
      href = item.href,
      imageUrl = item.imageUrl,
      title = item.title;
  return ___EmotionJSX(Box, {
    role: "listitem",
    "aria-label": title
  }, ___EmotionJSX(Link, {
    key: id,
    href: href,
    color: "typography.text",
    "aria-label": title,
    title: title,
    onClick: onClick
  }, ___EmotionJSX(Flex, {
    flexDirection: "column",
    alignItems: "center",
    rowGap: 2,
    justifyContent: {
      xs: "flex-start",
      md: "center"
    }
  }, ___EmotionJSX(Image, {
    src: imageUrl,
    alt: title,
    width: "auto",
    height: "auto",
    maxHeight: "150px",
    maxWidth: "100%"
  }), ___EmotionJSX(Span, {
    mt: {
      xs: 0,
      md: 2
    },
    fontSize: "base",
    fontWeight: "medium",
    textAlign: "center"
  }, title))));
};