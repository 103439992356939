import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box, Link } from "@catchoftheday/cg-components";
import { date } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FeaturedLink = function FeaturedLink(_ref) {
  var searchQuery = _ref.searchQuery;
  var featuredLinks = [{
    id: "halloween-link",
    matchingTerms: ["halloween"],
    title: "Find more ghoulishly great Halloween deals here 🎃",
    url: "/shop/halloween/",
    props: {
      color: "tertiaryDark",
      fontSize: "base",
      hoverColor: "tertiaryDark"
    },
    startDate: new Date("2022-10-05"),
    endDate: new Date("2022-10-31")
  }];
  var links = featuredLinks.map(function (featuredLink) {
    if (featuredLink.matchingTerms.find(function (term) {
      return searchQuery.toLowerCase().includes(term.toLowerCase());
    })) {
      var id = featuredLink.id,
          title = featuredLink.title,
          url = featuredLink.url,
          props = featuredLink.props,
          startDate = featuredLink.startDate,
          endDate = featuredLink.endDate;
      var now = new Date();

      if (date.isBefore(startDate, now) && date.isBefore(now, endDate)) {
        return ___EmotionJSX(Box, {
          mt: "12px",
          key: id
        }, ___EmotionJSX(Link, _extends({
          href: url
        }, props, {
          id: id
        }), title));
      }

      return null;
    }

    return null;
  });
  return ___EmotionJSX(React.Fragment, null, links);
};