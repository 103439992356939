import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { SEARCH_FACET_TYPE_CAROUSEL } from "../../helpers/search/search.helpers.facets";
/**
 * Returns array of facets and values which have been applied.
 * @param {Facet[]} facets - Array of facets
 * @returns {Array}
 */

var facetsApplied = function facetsApplied(facets) {
  var reduceFacetsList = function reduceFacetsList(values) {
    if (!values.some(function (value) {
      return value.selected;
    })) {
      return [];
    }

    return values.reduce(function (array, value) {
      var name = value.name,
          selected = value.selected;
      return !selected ? array : [].concat(_toConsumableArray(array), [name]);
    }, []);
  };

  var reduceFacetsKeyedList = function reduceFacetsKeyedList(values) {
    return Object.keys(values).reduce(function (array, key) {
      if (!values[key].some(function (value) {
        return value.selected;
      })) {
        return array;
      }

      var items = values[key].reduce(function (array, value) {
        var name = value.name,
            selected = value.selected;
        return !selected ? array : [].concat(_toConsumableArray(array), ["".concat(key, " ").concat(name)]);
      }, []);
      return [].concat(_toConsumableArray(array), _toConsumableArray(items));
    }, []);
  };

  return facets.reduce(function (array, facet) {
    var name = facet.name,
        values = facet.values,
        type = facet.type; // ignore carousel facets

    if (type === SEARCH_FACET_TYPE_CAROUSEL) {
      return array;
    }

    var selected = Array.isArray(values) ? reduceFacetsList(values) : reduceFacetsKeyedList(values);
    return !selected.length ? array : [].concat(_toConsumableArray(array), [{
      name: name,
      values: selected
    }]);
  }, []);
};

export default facetsApplied;