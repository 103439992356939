import { createSelector } from "reselect";
import { facetApplied } from "@search-app/utilities/facets";
import { facetAppliedCount } from "@search-app/utilities/facets/applied";
import searchFacetsApplied from "../../utilities/search/search.utilities.facetsApplied";
import searchSortOptionToOptionType from "../../utilities/search/search.utilities.sortOptionToOptionType";
import transformFacets from "../../utilities/search/search.utilities.transformFacets";
/** Search facets available*/

var searchFacets = function searchFacets(state) {
  return state.search.facets;
};
/** Show search results*/


export var searchError = function searchError(state) {
  return state.search.error;
};
export var searchResults = function searchResults(state) {
  return state.search.results;
};
export var searchPageLimit = function searchPageLimit(state) {
  return state.search.metadata.limit;
};

var searchPageCurrent = function searchPageCurrent(state) {
  return state.search.metadata.page;
};

export var searchSortOptions = function searchSortOptions(state) {
  return state.search.metadata.sortOptions || [];
};
/** Whether the current query has filterable facets */

export var hasFacets = function hasFacets(state) {
  return !!state.search.facets.length;
};
/** Does the search query have any hits*/

export var hasSearchHits = function hasSearchHits(state) {
  return !!state.search.metadata.hits;
};
/**Show search payload*/

export var searchHits = function searchHits(state) {
  return state.search.metadata.hits;
};
/** Navigation Items available*/

export var shopNavigationItems = function shopNavigationItems(state) {
  return state.searchConfig.navigationItems;
};
/** Is search still loading state*/

export var isSearchLoading = function isSearchLoading(state) {
  return state.search.isLoading;
};
/** Is it the first search*/

export var isInitialSearch = function isInitialSearch(state) {
  return state.search.isInitialSearch;
};
/** Show seo description if available on the page*/

export var seoDescription = function seoDescription(state) {
  var _state$seo;

  return (_state$seo = state.seo) === null || _state$seo === void 0 ? void 0 : _state$seo.footerHtmlContent;
};
/** Is the adult popup modal open*/

export var isAdultModalOpen = function isAdultModalOpen(state) {
  return state.adultModal.open;
};
/** Show page heading*/

export var pageHeading = function pageHeading(state) {
  return state.searchConfig.pageHeading;
};
/** Show search context heading*/

export var searchContext = function searchContext(state) {
  return state.searchConfig.context;
};
/** Has search been corrected*/

export var hasSearchCorrection = function hasSearchCorrection(state) {
  return !!state.search.metadata.autocorrect && !!state.search.metadata.queryOriginal;
};
/** Does the product have any sorting options available?*/

export var hasSearchSortOptions = function hasSearchSortOptions(state) {
  var _state$search$metadat;

  return !!((_state$search$metadat = state.search.metadata.sortOptions) !== null && _state$search$metadat !== void 0 && _state$search$metadat.length);
};
/** Page currently being looked at */

export var pageCurrent = function pageCurrent(state) {
  return state.search.metadata.page;
};
/** Total number of pages that the results fill */

export var pageTotal = function pageTotal(state) {
  return state.search.metadata.pages;
};
/** Search Query used*/

export var searchQuery = function searchQuery(state) {
  return state.search.metadata.query;
};
/** Breadcrumbs available to the query*/

export var breadCrumbs = function breadCrumbs(state) {
  return state.searchConfig.breadcrumbs;
};
/** Related categories for the category page navigation*/

export var relatedCategories = function relatedCategories(state) {
  return state.searchConfig.relatedCategories;
};
/** Origin of the query*/

export var queryOrigin = function queryOrigin(state) {
  return state.search.metadata.queryOriginal;
};
/** Are you able to clear all search terms*/

export var isClearAll = function isClearAll(state) {
  return state.search.isClearAll;
};
/** Visible facets*/

export var visibleFacets = function visibleFacets(state) {
  return state.search.hasVisibleFacets;
};
/** Applied facets*/

export var appliedFacets = function appliedFacets(state) {
  return state.search.appliedFacets;
};
/**
 * Transform search sort options to array of OptionTypes
 */

export var sortOptions = createSelector(searchSortOptions, function (sortOptions) {
  return sortOptions.map(function (option) {
    return searchSortOptionToOptionType(option);
  });
});
/**
 * Find selected search sort option and transform to OptionType
 */

export var sortOptionSelected = createSelector(searchSortOptions, function (sortOptions) {
  return searchSortOptionToOptionType(sortOptions.find(function (option) {
    return option.selected;
  }));
});
/**
 * Check if facets are applied and returns boolean value
 * @returns {Boolean}
 */

export var hasFacetsApplied = createSelector(searchFacets, function (facets) {
  return facets.filter(function (facet) {
    return facet.type !== "carousel";
  }).some(facetApplied);
});
/**
 * Check if facets are applied and returns total
 * @returns {(String|undefined)}
 */

export var totalFacetsApplied = createSelector(searchFacets, function (facets) {
  var totalFacets = facets.reduce(function (total, facet) {
    return total + facetAppliedCount(facet);
  }, 0);

  if (totalFacets > 0) {
    return totalFacets > 9 ? "9+" : "".concat(totalFacets);
  }

  return undefined;
});
/**
 * Returns array of facets and values which have been applied
 * @returns {Array}
 */

export var facetsApplied = createSelector(searchFacets, function (facets) {
  return searchFacetsApplied(facets);
});
/*
 * Returns array of facets with size name be transformed with standard string
 * @returns {Array}
 */

export var facetsTransformer = createSelector(searchFacets, function (facets) {
  return transformFacets(facets);
});
/**
 * Returns array of products for ecommerceData layer
 */

export var ecommerceDataProducts = function ecommerceDataProducts(state) {
  var results = searchResults(state);
  var pageLimit = searchPageLimit(state);
  var pageCurrent = searchPageCurrent(state);

  if (pageLimit === undefined || pageCurrent === undefined) {
    return [];
  }

  return results.map(function (result, index) {
    var id = result.id,
        title = result.title,
        brand = result.brand,
        category = result.category,
        subCategory = result.subCategory,
        clubCatchExclusive = result.clubCatchExclusive,
        priceTag = result.priceTag;
    var displayCategory = category;

    if (category && subCategory) {
      displayCategory += "/".concat(subCategory);
    }

    var offset = pageLimit * (pageCurrent - 1);
    var position = offset + (index + 1);
    var price = priceTag === null || priceTag === void 0 ? void 0 : priceTag.priceLabel.price.amount;
    return {
      id: "".concat(id),
      list: "search",
      quantity: "1",
      position: "".concat(position),
      price: price ? price / 100 : undefined,
      name: title,
      brand: (brand === null || brand === void 0 ? void 0 : brand.name) || "",
      category: displayCategory || "",
      dualPricing: !!(priceTag !== null && priceTag !== void 0 && priceTag.clubSellPrice),
      clubCatchExclusive: clubCatchExclusive || false
    };
  });
};