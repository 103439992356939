import React from "react";
import { Box, Heading, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CategoryDescription = function CategoryDescription(_ref) {
  var title = _ref.title,
      description = _ref.description,
      containerProps = _ref.containerProps;
  return ___EmotionJSX(Box, containerProps, ___EmotionJSX(Heading.h4, {
    mt: 0,
    mb: 2,
    fontWeight: "bold"
  }, title), ___EmotionJSX(Text, {
    fontSize: "xs",
    m: 0
  }, description));
};