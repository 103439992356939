import React from "react";
import { Flex, Heading, List, ListItem, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NoResultsHeader = function NoResultsHeader(_ref) {
  var searchQuery = _ref.searchQuery;
  return ___EmotionJSX(Flex, {
    flexDirection: "column",
    px: 3
  }, ___EmotionJSX(Heading.h1, {
    mb: 0,
    mt: {
      xs: 4,
      md: 0
    },
    fontSize: {
      xs: "2xl",
      md: "3xl"
    }
  }, "Sorry, we couldn't find any results for \"".concat(searchQuery, "\"")), ___EmotionJSX(Text, {
    fontSize: "base",
    mb: 1
  }, "To improve your search you can try:"), ___EmotionJSX(List, {
    type: "disc",
    style: {
      listStylePosition: "outside"
    },
    pl: 5,
    fontWeight: "light",
    mt: "0",
    lineHeight: "1.5rem"
  }, ___EmotionJSX(ListItem, null, "Search with fewer words or checking your spelling"), ___EmotionJSX(ListItem, null, "Search with more general terms"), ___EmotionJSX(ListItem, null, "Search a similar keyword")));
};