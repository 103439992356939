import { SEARCH_FACET_2COLS_THRESHOLD_LENGTH } from "../../helpers/search/search.helpers.facets";

var getCols = function getCols(hardCodedCols, displayedValues) {
  if (displayedValues.length === 1) {
    return 1;
  }

  if (hardCodedCols !== 0) {
    return hardCodedCols;
  }

  var hasLongItems = displayedValues.some(function (value) {
    var displayText = "".concat(value.name, " (").concat(value.count, ")");
    return displayText.length >= SEARCH_FACET_2COLS_THRESHOLD_LENGTH;
  });

  if (hasLongItems) {
    return 1;
  }

  return 2;
};

export default getCols;