import React from "react";
import { emitTacticalCategory, SOURCE_TYPE_SEARCH_IN_CUSTOMER_CATEGORY_SAJARI, useTriggerImpression } from "@catchoftheday/analytics";
import { Box } from "@catchoftheday/cg-components";
import { TrendingProducts } from "@catchoftheday/personalisation";
import { useIsInViewport } from "@catchoftheday/utilities";
import { SectionHeading } from "../SectionHeading";
import { jsx as ___EmotionJSX } from "@emotion/react";

var CarouselWrapper = function CarouselWrapper(_ref) {
  var children = _ref.children;

  var _useIsInViewport = useIsInViewport(),
      ref = _useIsInViewport.ref,
      inView = _useIsInViewport.inView;

  useTriggerImpression({
    emitFn: function emitFn() {
      emitTacticalCategory({
        action: "impression",
        label: "recommended-products"
      });
    },
    inView: inView
  });
  return ___EmotionJSX(Box, {
    ref: ref
  }, ___EmotionJSX(SectionHeading, {
    title: "Best Value Products"
  }), children);
};

export var TrendingProductsCarousel = function TrendingProductsCarousel(_ref2) {
  var category = _ref2.category;
  return ___EmotionJSX(TrendingProducts, {
    minCount: 5,
    category: category,
    Wrapper: CarouselWrapper,
    sourceType: SOURCE_TYPE_SEARCH_IN_CUSTOMER_CATEGORY_SAJARI
  });
};