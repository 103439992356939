import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { routeToQueryParam } from "@search-app/utilities/router";
export function clearAllFacets() {
  var urlSearchParams = new URLSearchParams(globalThis.location.search);
  var params = Object.fromEntries(urlSearchParams.entries());
  var queryParams = Object.keys(params).map(function (key) {
    if (key.match(/^f\[.*\]/)) {
      return {
        key: key,
        value: null
      };
    }

    return {
      key: key,
      value: urlSearchParams.get(key)
    };
  });
  routeToQueryParam([].concat(_toConsumableArray(queryParams), [{
    key: "page",
    value: 1
  }]));
}