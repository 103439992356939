import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AppExperimentContext } from "@catchoftheday/analytics";
import { Box } from "@catchoftheday/cg-components";
import { appliedFacetsToQueryObject } from "@search-app/api";
import { SEARCH_FACET_TYPE_KEYED_LIST, SEARCH_FACET_TYPE_LIST, SEARCH_FACET_TYPE_NESTED, SEARCH_FACET_TYPE_NESTED_SINGLE, SEARCH_FACET_TYPE_RANGE } from "@search-app/helpers/search/search.helpers.facets";
import { useShallowSelector } from "@search-app/store";
import { appliedFacets, facetsTransformer, isClearAll, isSearchLoading, searchError, searchQuery } from "@search-app/store/search/selectors";
import { routeToQueryParam } from "@search-app/utilities/router";
import getFacetHardCodedCols from "@search-app/utilities/search/search.utilities.getFacetHardCodedCols";
import { SearchFacetsApplied } from "./components/SearchFacetsApplied";
import { SearchFacetsCategory } from "./components/SearchFacetsCategory";
import { SearchFacetsKeyedList } from "./components/SearchFacetsKeyedList";
import { SearchFacetsList } from "./components/SearchFacetsList";
import { SearchFacetsNested } from "./components/SearchFacetsNested";
import { SearchFacetsNestedSingle } from "./components/SearchFacetsNestedSingle";
import { SearchFacetsRange } from "./components/SearchFacetsRange";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TRENDING_BRANDS_FACET_ID = "trending_brand";
var defaultExpandedIds = ["category_correlated", "category", "trending_brand", "price_range", "rating_categories", "delivery", "seller_type", "onepass"];
var mobileDefaultExpandedIds = ["category_correlated", "category"];
export var SearchFacetsInternal = function SearchFacetsInternal(_ref) {
  var isClearAll = _ref.isClearAll,
      _ref$facets = _ref.facets,
      facets = _ref$facets === void 0 ? [] : _ref$facets,
      _ref$appliedFacets = _ref.appliedFacets,
      appliedFacets = _ref$appliedFacets === void 0 ? [] : _ref$appliedFacets,
      isLoading = _ref.isLoading,
      searchTimeout = _ref.searchTimeout,
      noncollapsible = _ref.noncollapsible,
      scrollable = _ref.scrollable,
      searchQuery = _ref.searchQuery,
      isMobile = _ref.isMobile;
  var facetsAppliedId = "applied-facets";
  var defaultExpanded = useMemo(function () {
    return [facetsAppliedId].concat(_toConsumableArray(isMobile ? mobileDefaultExpandedIds : defaultExpandedIds));
  }, [isMobile]);
  var facetsWithApplied = useMemo(function () {
    return [].concat(_toConsumableArray(facets), [{
      id: facetsAppliedId,
      expanded: true
    }]);
  }, [facets]);

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["search_category_filters_apr23"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      categoryExperiment = _getExperiments$exper[0],
      expLoading = _getExperiments.loading;

  if (!facets.length) {
    return null;
  }

  var onChange = useCallback(function (_ref2) {
    var facet = _ref2.facet,
        value = _ref2.value,
        type = _ref2.type;
    var queryObject = appliedFacetsToQueryObject(appliedFacets, facet, value, type);
    var groupedFacets = Object.keys(queryObject).map(function (key) {
      return {
        key: "f[".concat(key, "]"),
        value: queryObject[key].join(";")
      };
    });
    routeToQueryParam([].concat(_toConsumableArray(groupedFacets), [{
      key: "page",
      value: 1
    }]));
  }, [appliedFacets]);
  var onClear = useCallback(function (_ref3) {
    var facet = _ref3.facet,
        type = _ref3.type;
    var facetsToClear = [{
      key: "f[".concat(facet, "]"),
      value: null
    }];

    if (type === SEARCH_FACET_TYPE_RANGE) {
      facetsToClear = [{
        key: "f[".concat(facet, ":min]"),
        value: null
      }, {
        key: "f[".concat(facet, ":max]"),
        value: null
      }];
    }

    routeToQueryParam([].concat(_toConsumableArray(facetsToClear), [{
      key: "page",
      value: 1
    }]));
  }, []);
  var getNewDefaultExpandedMap = useCallback(function () {
    return new Map(facetsWithApplied.map(function (facet) {
      var id = facet.id;
      return [id, defaultExpanded.includes(id)];
    }));
  }, [facetsWithApplied, defaultExpanded]);

  var _useState = useState(getNewDefaultExpandedMap()),
      expandMap = _useState[0],
      setExpandMap = _useState[1];

  useEffect(function () {
    setExpandMap(getNewDefaultExpandedMap());
  }, [getNewDefaultExpandedMap]);
  useEffect(function () {
    if (isClearAll) {
      setExpandMap(getNewDefaultExpandedMap());
      return;
    } //  update facet expand status


    var newExpandMap = new Map(expandMap);
    var idArr = facetsWithApplied.map(function (facet) {
      return facet.id;
    });
    expandMap.forEach(function (_value, key) {
      if (!idArr.includes(key)) {
        newExpandMap["delete"](key);
      }
    });
    facetsWithApplied.forEach(function (facet) {
      var id = facet.id;

      if (!expandMap.has(id)) {
        newExpandMap.set(id, false);
      }
    });
    setExpandMap(newExpandMap);
  }, [isClearAll, facetsWithApplied, noncollapsible, getNewDefaultExpandedMap]);
  var onExpandChange = useCallback(function (facetId, checked, element) {
    setExpandMap(function (oldExpandMap) {
      var id = facetId.slice(2, -1);
      var newExpandMap = new Map(oldExpandMap);
      newExpandMap.set(id, checked);
      return newExpandMap;
    });

    if (checked && isMobile && element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "nearest"
      });
    }
  }, [isMobile]);
  return ___EmotionJSX(Box, {
    position: "relative",
    style: isLoading ? {
      pointerEvents: "none"
    } : undefined
  }, appliedFacets.length > 0 && ___EmotionJSX(SearchFacetsApplied, {
    id: facetsAppliedId,
    onExpandChange: onExpandChange,
    onChange: onChange,
    onClear: onClear,
    appliedFacets: appliedFacets,
    isExpanded: expandMap.has(facetsAppliedId) ? expandMap.get(facetsAppliedId) : false,
    isMobile: !!isMobile
  }), facets.map(function (facet) {
    var id = facet.id;
    var hardCodedCols = getFacetHardCodedCols(facet);
    var isExpanded = expandMap.has(id) ? expandMap.get(id) : false;
    var facetProps = {
      key: id,
      hardCodedCols: hardCodedCols,
      isLoading: isLoading,
      isClearAll: isClearAll,
      isExpanded: noncollapsible || isExpanded,
      onChange: onChange,
      onClear: onClear,
      onExpandChange: onExpandChange,
      noncollapsible: noncollapsible,
      scrollable: scrollable,
      searchTimeout: searchTimeout
    };

    switch (facet.type) {
      case SEARCH_FACET_TYPE_KEYED_LIST:
        return ___EmotionJSX(SearchFacetsKeyedList, _extends({}, facetProps, {
          facet: facet
        }));

      case SEARCH_FACET_TYPE_LIST:
        return ___EmotionJSX(SearchFacetsList, _extends({}, facetProps, {
          facet: facet,
          searchQuery: searchQuery,
          isBrandFacet: facet.id === TRENDING_BRANDS_FACET_ID
        }));

      case SEARCH_FACET_TYPE_NESTED:
        return categoryExperiment && categoryExperiment.bucket > 0 ? ___EmotionJSX(SearchFacetsCategory, _extends({}, facetProps, {
          isExpanded: isExpanded && !expLoading,
          facet: facet
        })) : ___EmotionJSX(SearchFacetsNested, _extends({}, facetProps, {
          isExpanded: isExpanded && !expLoading,
          facet: facet
        }));

      case SEARCH_FACET_TYPE_NESTED_SINGLE:
        {
          if (categoryExperiment && categoryExperiment.bucket > 0) {
            return null;
          }

          return ___EmotionJSX(SearchFacetsNestedSingle, _extends({}, facetProps, {
            facet: facet
          }));
        }

      case SEARCH_FACET_TYPE_RANGE:
        return ___EmotionJSX(SearchFacetsRange, _extends({}, facetProps, {
          facet: facet
        }));

      default:
        return null;
    }
  }));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      facets: facetsTransformer(state),
      appliedFacets: appliedFacets(state),
      isClearAll: isClearAll(state),
      isLoading: isSearchLoading(state),
      searchTimeout: searchError(state),
      searchQuery: searchQuery(state)
    };
  });
};
/**
 * For external API props being passed into the component
 */


export var SearchFacets = function SearchFacets(props) {
  var state = useReduxState();
  return ___EmotionJSX(SearchFacetsInternal, _extends({}, state, props));
};