import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["headingProps", "title"],
    _excluded2 = ["children"];
import React from "react";
import { Flex, Heading } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SectionHeading = function SectionHeading(_ref) {
  var headingProps = _ref.headingProps,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, _excluded);

  var children = props.children,
      restProps = _objectWithoutProperties(props, _excluded2);

  return ___EmotionJSX(Flex, _extends({
    alignItems: "center",
    gap: 4,
    mb: 4,
    justifyContent: {
      xs: "space-between",
      md: "left"
    }
  }, restProps), ___EmotionJSX(Heading.h2, _extends({
    m: 0
  }, headingProps), title), children);
};