var getSelectedDrilldownCategoryLabel = function getSelectedDrilldownCategoryLabel(values) {
  var selectedCategory = values.filter(function (value) {
    return value.selected;
  });

  if (!selectedCategory.length) {
    return "";
  }

  var selectedDrilldownCategory = selectedCategory[0].values ? getSelectedDrilldownCategoryLabel(selectedCategory[0].values) : null;

  if (selectedDrilldownCategory.length !== 0) {
    return selectedDrilldownCategory;
  }

  return selectedCategory[0].name;
};

export default getSelectedDrilldownCategoryLabel;