import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useContext } from "react";
import { AppExperimentContext, SOURCE_TYPE_SEARCH_TOP_SPONSORED_BANNER } from "@catchoftheday/analytics";
import { PageBanner, useBannerByPageQuery } from "@catchoftheday/common-vendors";
import { RetailMediaBanner } from "@catchoftheday/seller";
import { getSalesChannel } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Banner = function Banner(_ref) {
  var _data$bannerByPage, _data$bannerByPage$ba;

  var host = _ref.host,
      isMobile = _ref.isMobile,
      searchQuery = _ref.searchQuery;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["retail_media_search_banner_jan_24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      retailMediaExperiment = _getExperiments$exper[0];

  var _useBannerByPageQuery = useBannerByPageQuery({
    variables: {
      input: {
        page: "SEARCH"
      }
    },
    ssr: true
  }),
      data = _useBannerByPageQuery.data;

  if (data !== null && data !== void 0 && (_data$bannerByPage = data.bannerByPage) !== null && _data$bannerByPage !== void 0 && (_data$bannerByPage$ba = _data$bannerByPage.banners) !== null && _data$bannerByPage$ba !== void 0 && _data$bannerByPage$ba.length) {
    return ___EmotionJSX(PageBanner, {
      isMobile: isMobile,
      pageType: "SEARCH",
      bannerData: data.bannerByPage.banners
    });
  }

  if (retailMediaExperiment !== null && retailMediaExperiment !== void 0 && retailMediaExperiment.bucket && getSalesChannel(host) !== "CANZ") {
    return ___EmotionJSX(RetailMediaBanner, {
      isMobile: isMobile,
      inventoryId: "search_top_banner",
      searchQuery: searchQuery,
      sourceId: searchQuery,
      sourceType: SOURCE_TYPE_SEARCH_TOP_SPONSORED_BANNER,
      pageId: "search",
      mb: {
        xs: 4,
        md: 6
      }
    });
  }

  return null;
};