import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var FacetsContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:first-of-type {\n    margin-top: 0;\n  }\n"])));
export var SearchFacetsContainer = function SearchFacetsContainer(_ref) {
  var children = _ref.children,
      id = _ref.id;
  return ___EmotionJSX(FacetsContainer, {
    my: "10px",
    backgroundColor: "white",
    id: id
  }, children);
};