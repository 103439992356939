import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useContext } from "react";
import { AppExperimentContext, SOURCE_TYPE_SEARCH_BOTTOM_SPONSORED_BANNER } from "@catchoftheday/analytics";
import { RetailMediaBanner } from "@catchoftheday/seller";
import { getSalesChannel } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var RetailMediaBannerBottom = function RetailMediaBannerBottom(_ref) {
  var host = _ref.host,
      isMobile = _ref.isMobile,
      searchQuery = _ref.searchQuery;

  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["retail_media_search_banner_bottom_feb_24"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      retailMediaExperiment = _getExperiments$exper[0];

  if (!(retailMediaExperiment !== null && retailMediaExperiment !== void 0 && retailMediaExperiment.bucket) || getSalesChannel(host) == "CANZ") {
    return null;
  }

  return ___EmotionJSX(RetailMediaBanner, {
    isMobile: !!isMobile,
    inventoryId: "search_bottom_banner",
    searchQuery: searchQuery,
    sourceId: searchQuery,
    sourceType: SOURCE_TYPE_SEARCH_BOTTOM_SPONSORED_BANNER,
    pageId: "search",
    mt: 5
  });
};