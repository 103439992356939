import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box } from "../../components/Box";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PageContainer = function PageContainer(styledProps) {
  return ___EmotionJSX(Box, _extends({
    as: "main",
    id: "maincontent",
    bg: "#FAFAFA",
    mx: "auto",
    my: "0"
  }, styledProps));
};
PageContainer.displayName = "PageContainer";
export default PageContainer;