import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { getProductTiles, RecommenderCarousel } from "@catchoftheday/personalisation";
import { jsx as ___EmotionJSX } from "@emotion/react";
// Retaining this to enable tracking, but setting null as we don't have a from product in this case.
var fromProduct = {
  from_product_ids: null,
  from_product_offer_id: null,
  from_product_displayed_price: null
};
export var TopSellersRecommender = function TopSellersRecommender(_ref) {
  var products = _ref.products,
      _ref$sourceId = _ref.sourceId,
      sourceId = _ref$sourceId === void 0 ? "" : _ref$sourceId,
      sourceType = _ref.sourceType,
      isMobile = _ref.isMobile;
  var tiles = getProductTiles({
    products: products.map(function (product, index) {
      return _objectSpread(_objectSpread({}, product), {}, {
        sourceType: sourceType,
        sourcePosition: index + 1,
        isProductItem: true
      });
    }),
    sourceType: sourceType,
    sourceId: sourceId,
    fromProduct: fromProduct
  });
  var trackingData = products.map(function (_ref2) {
    var id = _ref2.id,
        title = _ref2.title,
        offerId = _ref2.offerId,
        priceTag = _ref2.priceTag;
    return {
      id: id,
      title: title,
      offerId: offerId,
      sellPrice: priceTag ? priceTag.priceLabel.price.amount / 100 : undefined,
      sourceType: sourceType,
      sourceId: sourceId,
      type: "product"
    };
  });
  return ___EmotionJSX(Box, {
    overflow: "hidden",
    mx: {
      xs: -3,
      md: 0
    }
  }, ___EmotionJSX(RecommenderCarousel, {
    tiles: tiles,
    sourceType: sourceType,
    sourceId: sourceId,
    maxTiles: tiles.length,
    trackingData: trackingData,
    carouselProps: {
      containerProps: {
        marginLeft: {
          xs: "30px",
          md: "-30px"
        },
        marginRight: {
          xs: "30px",
          md: "-30px"
        }
      },
      buttonOffset: [40, 0],
      showNavButtons: !isMobile
    },
    wrapperProps: {
      px: 0
    },
    fromProduct: fromProduct
  }));
};