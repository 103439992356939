import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["onClickHandler", "showLess"];

var _templateObject;

import React from "react";
import { Button, IconDownArrow } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var HoverButton = styled(Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:hover:not(:disabled):not([disabled]) {\n    color: ", ";\n  }\n"])), themeGet("colors.secondary"));
export var ShowMore = function ShowMore(_ref) {
  var onClickHandler = _ref.onClickHandler,
      _ref$showLess = _ref.showLess,
      showLess = _ref$showLess === void 0 ? false : _ref$showLess,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(HoverButton, _extends({
    variant: "plain",
    sizeVariant: "xs",
    onClick: function onClick() {
      return onClickHandler(showLess);
    },
    textTransform: "capitalize",
    fontSize: "0.875rem",
    px: 0,
    mx: "16px",
    border: "none"
  }, props), showLess ? "Show Less" : "Show More", ___EmotionJSX(IconDownArrow, {
    width: "10px",
    height: "10px",
    ml: "5px",
    style: {
      rotate: showLess ? "180deg" : "0deg"
    }
  }));
};