import React, { useCallback } from "react";
import { emitTacticalCategory, SOURCE_TYPE_SEARCH_IN_CUSTOMER_CATEGORY_SAJARI, useTriggerImpression } from "@catchoftheday/analytics";
import { Box, Image, Link } from "@catchoftheday/cg-components";
import { getEffectiveUrl, useIsInViewport } from "@catchoftheday/utilities";
import { SectionHeading } from "../SectionHeading";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TopBrands = function TopBrands(_ref) {
  var categoryName = _ref.categoryName,
      brands = _ref.brands;
  var trackBrandClicks = useCallback(function (name) {
    emitTacticalCategory({
      action: "click",
      label: "top-brands",
      property: name
    });
  }, []);

  var _useIsInViewport = useIsInViewport(),
      ref = _useIsInViewport.ref,
      inView = _useIsInViewport.inView;

  useTriggerImpression({
    emitFn: function emitFn() {
      emitTacticalCategory({
        action: "impression",
        label: "top-brands"
      });
    },
    inView: inView
  });
  return ___EmotionJSX(Box, {
    ref: ref
  }, ___EmotionJSX(SectionHeading, {
    title: "Top Brands in ".concat(categoryName)
  }), ___EmotionJSX(Box, {
    display: "grid",
    gridTemplateRows: {
      xs: "repeat(2, auto)",
      lg: "auto"
    },
    gridTemplateColumns: {
      xs: "repeat(4, auto)",
      lg: "auto"
    },
    gridAutoFlow: {
      xs: "row",
      lg: "column"
    },
    gap: {
      xs: 2,
      md: 6
    },
    justifyContent: "space-between",
    justifyItems: "center",
    alignItems: "center"
  }, brands.map(function (_ref2) {
    var name = _ref2.name,
        imageUrl = _ref2.imageUrl,
        url = _ref2.url;
    return ___EmotionJSX(Link, {
      href: getEffectiveUrl({
        sourceType: SOURCE_TYPE_SEARCH_IN_CUSTOMER_CATEGORY_SAJARI,
        url: url
      }),
      "aria-label": name,
      key: "brand-".concat(name),
      onClick: function onClick() {
        return trackBrandClicks(name);
      }
    }, ___EmotionJSX(Image, {
      maxHeight: "75px",
      width: "100%",
      height: "100%",
      alt: "Brand logo of ".concat(name),
      src: imageUrl
    }));
  })));
};