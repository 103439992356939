import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useEffect } from "react";
import { ArrowLeftIcon, Box, Link, List, ListItem } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { sessionStorage } from "@catchoftheday/utilities";
import { SEARCH_FACET_SHOWMORE_LIMIT, SEARCH_FACET_TYPE_NESTED } from "@search-app/helpers/search/search.helpers.facets";
import { SEARCH_URL_PARAM_FACET } from "@search-app/helpers/search/search.helpers.params";
import { facetAppliedCount } from "@search-app/utilities/facets/applied";
import getSelectedDrilldownCategoryLabel from "@search-app/utilities/search/search.utilities.getSelectedDrilldownCategoryLabel";
import { FacetCount } from "../../SearchFacets.styles";
import { SearchFacetsBody } from "../SearchFacetsBody";
import { SearchFacetsContainer } from "../SearchFacetsContainer";
import { SearchFacetsHeader } from "../SearchFacetsHeader";
import { ShowMore } from "../SearchFacetsShowMore/SearchFacetsShowMore";
import { jsx as ___EmotionJSX } from "@emotion/react";
var CategoryList = styled(List)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  li {\n    margin-bottom: 0.75rem;\n    &:last-of-type {\n      margin-bottom: 0;\n    }\n  }\n  li ul li {\n    &:first-of-type {\n      margin-top: 0.75rem;\n    }\n  }\n"])));
var IconLeftArrow = styled(ArrowLeftIcon)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  left: -24px;\n  top: -1px;\n"])));
export var SearchFacetsCategory = function SearchFacetsCategory(_ref) {
  var facet = _ref.facet,
      isExpanded = _ref.isExpanded,
      onClear = _ref.onClear,
      onChange = _ref.onChange,
      onExpandChange = _ref.onExpandChange,
      noncollapsible = _ref.noncollapsible,
      searchTimeout = _ref.searchTimeout;
  var id = facet.id,
      name = facet.name,
      values = facet.values;
  var facetId = "".concat(SEARCH_URL_PARAM_FACET, "[").concat(id, "]");
  var selectedLabel = values && getSelectedDrilldownCategoryLabel(values);
  var totalSelected = facetAppliedCount(facet);
  var showMoreLimit = SEARCH_FACET_SHOWMORE_LIMIT;

  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      visitedCategories = _React$useState2[0],
      setVisitedCategories = _React$useState2[1];

  var _React$useState3 = React.useState({}),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      showMoreMap = _React$useState4[0],
      setShowMoreMap = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      isCategoryPage = _React$useState6[0],
      setIsCategoryPage = _React$useState6[1];

  useEffect(function () {
    var isCategoryPage = window.location.pathname.startsWith("/category");
    setIsCategoryPage(isCategoryPage);
  }, []);
  useEffect(function () {
    var data = sessionStorage.getItem("visitedCategories");

    if (data) {
      setVisitedCategories(JSON.parse(data));
    }
  }, [facet]);
  useEffect(function () {
    var selectedCategoryPosition = function selectedCategoryPosition(values) {
      return values.findIndex(function (value) {
        return value.selected;
      });
    }; // We need to manage the show more state for each category level


    var newVals = {};

    function updateShowMoreValues(values) {
      values.forEach(function (_ref2) {
        var subId = _ref2.id,
            subValues = _ref2.values;
        newVals = _objectSpread(_objectSpread({}, newVals), {}, _defineProperty({}, subId, !!subValues && subValues.length >= showMoreLimit && selectedCategoryPosition(subValues) < showMoreLimit));

        if (subValues && subValues.length) {
          updateShowMoreValues(subValues);
        }
      });
      setShowMoreMap(_objectSpread(_objectSpread({}, newVals), {}, _defineProperty({}, id, values.length >= showMoreLimit && selectedCategoryPosition(values) < showMoreLimit)));
    }

    updateShowMoreValues(values);
  }, [id, values, facet, showMoreLimit]);

  function updateVisited(categoryId) {
    // Visisted categories get a purple link for the session
    if (!visitedCategories.includes(categoryId)) {
      sessionStorage.setItem("visitedCategories", JSON.stringify([].concat(_toConsumableArray(visitedCategories), [categoryId])));
    }
  }

  var handleClick = function handleClick(currentId, parentId, selected) {
    if (onClear && selected && !parentId) {
      return onClear({
        facet: id
      });
    }

    return onChange({
      facet: facet.id,
      value: currentId,
      type: SEARCH_FACET_TYPE_NESTED
    });
  };

  function renderList(values, parentId, isRoot) {
    var displayValues = values.slice(0, showMoreMap["".concat(parentId)] ? showMoreLimit : values.length);
    var extraIndent = isRoot && values.filter(function (value) {
      var _value$values;

      return (_value$values = value.values) === null || _value$values === void 0 ? void 0 : _value$values.filter(function (subVal) {
        return subVal.selected;
      }).length;
    }).length > 0;
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(CategoryList, {
      width: "100%",
      pl: extraIndent ? 8 : 4,
      m: 0
    }, displayValues.map(function (facet) {
      return renderListItem(facet, parentId);
    })), (values === null || values === void 0 ? void 0 : values.length) > showMoreLimit && ___EmotionJSX(ShowMore, {
      mx: parentId ? 4 : 0,
      onClickHandler: function onClickHandler(showLess) {
        return setShowMoreMap(_objectSpread(_objectSpread({}, showMoreMap), {}, _defineProperty({}, "".concat(parentId), showLess)));
      },
      showLess: !showMoreMap["".concat(parentId)]
    }));
  }

  function renderListItem(items, parentId) {
    var selected = items.selected,
        name = items.name,
        count = items.count,
        currentId = items.id,
        values = items.values,
        slug = items.slug;
    var hasChildren = values && values.length > 0;
    var currentSelection = selected && selectedLabel === name;
    var hasSelectedChildren = hasChildren && values.some(function (v) {
      return v.selected;
    });
    return ___EmotionJSX(ListItem, {
      key: currentId
    }, ___EmotionJSX(Box, {
      position: "relative"
    }, !currentSelection && hasSelectedChildren && ___EmotionJSX(IconLeftArrow, {
      width: 5,
      height: 5
    }), ___EmotionJSX(Box, null, ___EmotionJSX(Link, {
      onClick: function onClick(e) {
        if (isCategoryPage) {
          return;
        }

        e.preventDefault();

        if (currentSelection) {
          return;
        }

        handleClick(currentId, parentId, selected);
        updateVisited(currentId);
      },
      href: "/category/".concat(slug || currentId),
      color: currentSelection ? "secondary" : visitedCategories.includes(currentId) ? "purple" : "inherit",
      fontWeight: currentSelection ? "normal" : "light",
      hoverColor: "secondary",
      fontSize: "sm"
    }, name), count && ___EmotionJSX(FacetCount, null, "(".concat(count, ")")))), hasChildren && renderList(values, currentId));
  }

  var onClearHandler = useCallback(function () {
    if (onClear) {
      onClear({
        facet: id
      });
    }
  }, [onClear, id]);
  return ___EmotionJSX(SearchFacetsContainer, {
    id: facetId
  }, ___EmotionJSX(SearchFacetsHeader, {
    id: facetId,
    isExpanded: isExpanded,
    title: name,
    searchTimeout: searchTimeout,
    selectedLabel: selectedLabel,
    onClear: onClearHandler,
    onExpandChange: onExpandChange,
    noncollapsible: noncollapsible,
    totalSelected: totalSelected
  }), ___EmotionJSX(SearchFacetsBody, {
    isExpanded: isExpanded,
    pr: 4
  }, renderList(facet.values, facet.id, true)));
};