import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["children", "hasFacets", "hasFacetsModal"];

var _templateObject;

import React from "react";
import { Container } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var SearchPageContainer = styled(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return props.hasFacets && !props.hasFacetsModal && "\n      > article {\n        width: calc(100% - 327px);\n        padding-right: 10px;\n\n        @media only screen and (max-width: 1279px) and (min-width: 1024px) {\n          padding-right: 3px;\n        }\n      }\n  ";
});
export var SearchContainer = function SearchContainer(_ref) {
  var children = _ref.children,
      hasFacets = _ref.hasFacets,
      hasFacetsModal = _ref.hasFacetsModal,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(SearchPageContainer, _extends({
    py: 0,
    px: 3,
    position: "relative",
    hasFacets: hasFacets,
    hasFacetsModal: hasFacetsModal
  }, props), children);
};