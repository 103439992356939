import React from "react";
import { Box, Breadcrumbs, Button, Flex, Span } from "@catchoftheday/cg-components";
import { minusOnePx, useMedia, useTheme } from "@catchoftheday/theme";
import { useShallowSelector } from "@search-app/store";
import { breadCrumbs, hasFacets, hasFacetsApplied, hasSearchSortOptions, isSearchLoading, searchQuery, shopNavigationItems, totalFacetsApplied } from "@search-app/store/search/selectors";
import { clearAllFacets } from "@search-app/utilities/facets";
import { SearchContainer } from "../SearchContainer";
import { SearchFacetsToggle } from "./SearchHeader/SearchFacetsToggle";
import { SearchSortOptions } from "./SearchHeader/SearchSortOptions";
import { Banner } from "./Banner";
import { SearchHeader } from "./SearchHeader";
import { ShopNavigation, ShopNavigationPlaceholder } from "./ShopNavigation";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeaderContent = function HeaderContent(_ref) {
  var host = _ref.host;
  var theme = useTheme();
  var isMobile = useMedia(["(max-width: ".concat(minusOnePx(theme.breakpoints.md), ")")], [true]);

  var _useReduxState = useReduxState(),
      hasFacetsApplied = _useReduxState.hasFacetsApplied,
      hasFacets = _useReduxState.hasFacets,
      isLoading = _useReduxState.isLoading,
      shopNavigationItems = _useReduxState.shopNavigationItems,
      totalFacetsApplied = _useReduxState.totalFacetsApplied,
      hasSearchSortOptions = _useReduxState.hasSearchSortOptions,
      breadcrumbs = _useReduxState.breadcrumbs,
      searchQuery = _useReduxState.searchQuery;

  var mappedBreadcrumbs = (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.map(function (_ref2, index) {
    var name = _ref2.name,
        url = _ref2.url;
    return {
      label: name,
      href: url,
      leaf: index === breadcrumbs.length - 1
    };
  })) || [];

  var getBreadcrumbItemProps = function getBreadcrumbItemProps(index) {
    if (index === 0) {
      return {
        ml: {
          xs: 3,
          md: 8,
          lg: 3
        }
      };
    }

    return {};
  };

  return ___EmotionJSX(React.Fragment, null, isMobile && hasFacets && ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    mb: "24px",
    width: {
      xs: "100%",
      md: "250px"
    },
    height: {
      xs: "40px",
      md: "auto"
    },
    alignItems: "center",
    position: {
      xs: "sticky",
      md: "absolute"
    },
    top: {
      xs: "70px",
      md: "auto"
    },
    background: {
      xs: "white",
      md: "none"
    },
    zIndex: 2,
    boxShadow: {
      xs: "0 0 5px 5px rgba(0, 0, 0, 0.15)",
      md: "none"
    }
  }, ___EmotionJSX(Box, {
    width: "50%"
  }, ___EmotionJSX(SearchFacetsToggle, {
    totalFacetsApplied: totalFacetsApplied
  }), ___EmotionJSX(Span, {
    display: "block",
    position: "absolute",
    right: "50%",
    top: "9px",
    width: "1px",
    height: "22px",
    margin: "auto 0",
    backgroundColor: theme.colors.ui.borderColor
  })), hasSearchSortOptions && ___EmotionJSX(Box, {
    width: "50%"
  }, ___EmotionJSX(SearchSortOptions, null))), ___EmotionJSX(Box, null, ___EmotionJSX(Box, {
    paddingRight: {
      xs: 0,
      md: "20px"
    }
  }, searchQuery && ___EmotionJSX(Banner, {
    host: host,
    isMobile: !!isMobile,
    searchQuery: searchQuery
  })), mappedBreadcrumbs.length > 0 && ___EmotionJSX(Breadcrumbs, {
    breadcrumbs: mappedBreadcrumbs,
    listItemProps: getBreadcrumbItemProps
  }), ___EmotionJSX(SearchContainer, {
    hasFacetsModal: isMobile,
    display: "block"
  }, ___EmotionJSX(SearchHeader, {
    isMobile: isMobile
  }), shopNavigationItems && (isLoading ? ___EmotionJSX(ShopNavigationPlaceholder, null) : ___EmotionJSX(ShopNavigation, {
    shopNavigationItems: shopNavigationItems
  }))), ___EmotionJSX(Box, {
    m: "0 auto",
    maxWidth: "1340px",
    p: "0 10px"
  }, !hasFacets && hasFacetsApplied && ___EmotionJSX(Button, {
    variant: "secondary",
    sizeVariant: "sm",
    outline: true,
    width: "100%",
    maxWidth: "327px",
    mb: "20px",
    onClick: clearAllFacets
  }, "Clear All Filters"))));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      hasFacets: hasFacets(state),
      hasFacetsApplied: hasFacetsApplied(state),
      shopNavigationItems: shopNavigationItems(state),
      isLoading: isSearchLoading(state),
      totalFacetsApplied: totalFacetsApplied(state),
      hasSearchSortOptions: hasSearchSortOptions(state),
      breadcrumbs: breadCrumbs(state),
      searchQuery: searchQuery(state)
    };
  });
};