import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useEffect, useState } from "react";
import { SOURCE_TYPE_SEARCH_NO_RESULTS } from "@catchoftheday/analytics";
import { Box, Heading, IconSearch } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { useTrendingSearchTermsQuery } from "@search-app/generated/graphql";
import { SearchPillNavigation } from "../../SearchPillNavigation";
import { jsx as ___EmotionJSX } from "@emotion/react";
var NUM_TRENDING_TERMS = 6;
var TrendingTerm = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:hover {\n    text-decoration: underline;\n  }\n"])));
export var TrendingSearches = function TrendingSearches() {
  var _useState = useState([]),
      terms = _useState[0],
      setTerms = _useState[1];

  var _useTrendingSearchTer = useTrendingSearchTermsQuery({
    variables: {
      limit: NUM_TRENDING_TERMS
    }
  }),
      data = _useTrendingSearchTer.data;

  useEffect(function () {
    if (data !== null && data !== void 0 && data.trendingSearchTerms.terms) {
      var _terms = data === null || data === void 0 ? void 0 : data.trendingSearchTerms.terms.map(function (term) {
        return {
          name: ___EmotionJSX(TrendingTerm, null, ___EmotionJSX(IconSearch, {
            width: "14px",
            height: "14px",
            mr: 1
          }), term),
          url: "/search?query=".concat(term, "&st=").concat(SOURCE_TYPE_SEARCH_NO_RESULTS),
          selected: false
        };
      });

      setTerms(_terms);
    }
  }, [data]);
  return ___EmotionJSX(Box, {
    px: 3
  }, ___EmotionJSX(Heading.h2, {
    fontSize: {
      xs: "lg",
      md: "2xl"
    }
  }, "Try these common search terms"), ___EmotionJSX(SearchPillNavigation, {
    items: terms,
    pillContainerProps: {
      borderColor: "brand.primary",
      color: "brand.primary",
      borderWidth: "2px",
      hoverColor: "brand.primary",
      hoverBackgroundColor: "white",
      height: "44px"
    }
  }));
};