import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { Flex } from "../Flex";
import { PaginationItem } from "./PaginationItem";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PaginationItems = function PaginationItems(props) {
  var pageCurrent = props.pageCurrent,
      pageTotal = props.pageTotal;
  var items = [];
  /**
   * Push item props to array
   */

  var paginationAddItem = function paginationAddItem(page) {
    var item = _objectSpread(_objectSpread({}, props), {}, {
      page: page,
      "aria-label": "Go to page ".concat(page)
    });

    items.push(___EmotionJSX(PaginationItem, _extends({
      key: "button:".concat(item.page)
    }, item)));
  };
  /**
   * Push spacer element props to items array
   */


  var paginationAddSpacer = function paginationAddSpacer(key) {
    return items.push(___EmotionJSX(Flex, {
      key: key,
      m: "0 4px",
      alignItems: "center"
    }, ___EmotionJSX("span", null, "...")));
  };

  var paginationItemsRender = function paginationItemsRender() {
    return ___EmotionJSX(React.Fragment, null, items);
  };
  /**
   * Render all if less than or equal to maxDisplayedPages or 7 items
   */


  if (pageTotal <= (props.maxDisplayedPages || 7)) {
    for (var page = 1; page < pageTotal + 1; page++) {
      paginationAddItem(page);
    }

    return paginationItemsRender();
  }
  /**
   * Render first 5 items, at start of search results
   */


  if (pageCurrent <= 4) {
    var offset = pageCurrent === 1 ? 3 : 2;
    var pages = pageCurrent + offset;
    var start = 1;

    for (var _page = start; _page < pages; _page++) {
      paginationAddItem(_page);
    }

    paginationAddSpacer("end"); // Removing last page see SD-488
    // paginationAddItem(pageTotal);

    return paginationItemsRender();
  }
  /**
   * Render last 5 items, at end of search results
   */


  if (pageCurrent > pageTotal - 4) {
    var _offset = pageCurrent === pageTotal ? 2 : 1;

    var _pages = pageTotal;

    var _start = pageCurrent - _offset;

    paginationAddItem(1);
    paginationAddSpacer("start");

    for (var _page2 = _start; _page2 < _pages + 1; _page2++) {
      paginationAddItem(_page2);
    }

    return paginationItemsRender();
  }
  /**
   * Render first item, 3 intermediate items, then last item
   */


  paginationAddItem(1);
  paginationAddSpacer("start");

  for (var _page3 = pageCurrent - 1; _page3 < pageCurrent + 2; _page3++) {
    paginationAddItem(_page3);
  }

  paginationAddSpacer("end"); // Removing last page see SD-488
  // paginationAddItem(pageTotal);

  return paginationItemsRender();
};