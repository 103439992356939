import React from "react";
import { Box, Hide } from "@catchoftheday/cg-components";
import { minusOnePx, useMedia, useTheme } from "@catchoftheday/theme";
import { useShallowSelector } from "@search-app/store";
import { hasFacets, hasFacetsApplied, isInitialSearch } from "@search-app/store/search/selectors";
import { SearchFacets } from "../SearchFacets";
import { SidebarPlaceholder } from "./SidebarPlaceholder";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Sidebar = function Sidebar() {
  var theme = useTheme();

  var _useReduxState = useReduxState(),
      hasFacets = _useReduxState.hasFacets,
      isInitialSearch = _useReduxState.isInitialSearch;

  var isMobile = useMedia(["(max-width: ".concat(minusOnePx(theme.breakpoints.md), ")")], [true]);

  function render() {
    if (isMobile) {
      return null;
    }

    if (isInitialSearch) {
      return ___EmotionJSX(SidebarPlaceholder, null);
    }

    if (!hasFacets) {
      return null;
    }

    return ___EmotionJSX(Box, {
      as: "aside",
      width: "327px",
      pr: "15px",
      pl: {
        xs: "20px",
        lg: 0
      },
      position: "relative"
    }, ___EmotionJSX(SearchFacets, null));
  }

  return ___EmotionJSX(Hide, {
    xs: true,
    sm: true
  }, render());
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      hasFacets: hasFacets(state),
      hasFacetsApplied: hasFacetsApplied(state),
      isInitialSearch: isInitialSearch(state)
    };
  });
};