import React from "react";
import { Box, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NoResults = function NoResults() {
  return ___EmotionJSX(Box, {
    p: "0 16px"
  }, ___EmotionJSX(Text, {
    color: "tertiaryDark",
    mt: "0",
    fontSize: "xs",
    fontWeight: "normal",
    whiteSpace: "nowrap"
  }, "Sorry, no matching results found. Please try again."));
};