import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SEARCH_FACET_TYPE_KEYED_LIST } from "../../helpers/search/search.helpers.facets";
/**
 * Handles transforming KeyedListFacets by prepending the country name to the size name
 */

var transformFacetSizeName = function transformFacetSizeName(facet) {
  var type = facet.type,
      values = facet.values;

  if (type === SEARCH_FACET_TYPE_KEYED_LIST) {
    var copiedValues = _objectSpread({}, values);

    Object.entries(values).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          tagsArr = _ref2[1];

      if (key === "AU" || key === "US" || key === "UK" || key === "EU" || key === "BR") {
        copiedValues[key] = tagsArr.map(function (tag) {
          return _objectSpread(_objectSpread({}, tag), {}, {
            name: "".concat(key, " ").concat(tag.name)
          });
        });
      }
    });
    return _objectSpread(_objectSpread({}, facet), {}, {
      values: copiedValues
    });
  }

  return facet;
};

var transformFacets = function transformFacets(facets) {
  return facets.map(function (facet) {
    return transformFacetSizeName(facet);
  });
};

export default transformFacets;