import React, { useContext } from "react";
import { Box, Flex } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { TacticalCategoryContext } from "@search-app/contexts/TacticalCategoryContext";
import { CategoryDescription } from "../CategoryDescription";
import { CategoriesGrid } from "./CategoriesGrid";
import { FeaturedEvents } from "./FeaturedEvents";
import { TitleBanner } from "./TitleBanner";
import { TopBrands } from "./TopBrands";
import { TrendingProductsCarousel } from "./TrendingProductsCarousel";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TacticalCategory = function TacticalCategory() {
  var theme = useTheme();

  var _useContext = useContext(TacticalCategoryContext),
      isSearchResultsHidden = _useContext.isSearchResultsHidden,
      tacticalCategory = _useContext.tacticalCategory;

  if (!tacticalCategory) {
    return null;
  }

  var shopAllHref = tacticalCategory.shopAllHref,
      name = tacticalCategory.name,
      description = tacticalCategory.description,
      topBrands = tacticalCategory.topBrands,
      trendingProductsCategory = tacticalCategory.trendingProductsCategory,
      subcategories = tacticalCategory.subcategories,
      featuredEventIDs = tacticalCategory.featuredEventIDs;
  return ___EmotionJSX(Box, {
    px: {
      xs: 4,
      md: 8
    },
    pt: {
      xs: 4,
      md: 8
    }
  }, ___EmotionJSX(Flex, {
    maxWidth: theme.maxWidths.cotdWebsite,
    margin: "auto",
    rowGap: {
      xs: 6,
      md: 8
    },
    mb: {
      xs: 6,
      md: 8
    },
    flexDirection: "column"
  }, ___EmotionJSX(TitleBanner, {
    tacticalCategory: tacticalCategory
  }), ___EmotionJSX(CategoriesGrid, {
    isSearchResultsHidden: isSearchResultsHidden,
    subcategories: subcategories,
    shopAllHref: shopAllHref
  }), (featuredEventIDs === null || featuredEventIDs === void 0 ? void 0 : featuredEventIDs.length) && ___EmotionJSX(FeaturedEvents, {
    featuredEventIDs: featuredEventIDs
  }), (topBrands === null || topBrands === void 0 ? void 0 : topBrands.length) && ___EmotionJSX(TopBrands, {
    categoryName: name,
    brands: topBrands
  }), trendingProductsCategory && ___EmotionJSX(TrendingProductsCarousel, {
    category: trendingProductsCategory
  }), isSearchResultsHidden && ___EmotionJSX(CategoryDescription, {
    title: name,
    description: description
  })));
};