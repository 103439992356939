import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useEffect } from "react";
import { Box } from "@catchoftheday/cg-components";
import { ThemeProvider, useMedia, useTheme } from "@catchoftheday/theme";
import { LoadingSpinner } from "@search-app/components/LoadingSpinner";
import { useDispatch, useShallowSelector } from "@search-app/store";
import { searchFetch } from "@search-app/store/search/actions";
import { hasFacets, isInitialSearch, isSearchLoading, relatedCategories, searchHits, searchQuery } from "@search-app/store/search/selectors";
import { RetailMediaBannerBottom } from "../RetailMedia/BannerBottom";
import { SearchFacetsModal } from "./SearchFacetsModal/";
import { HeaderContent } from "./HeaderContent";
import { Results } from "./Results";
import { SearchContainer } from "./SearchContainer";
import { SearchMigrationNudge } from "./SearchMigrationNudge";
import { SearchPillNavigation } from "./SearchPillNavigation";
import { Sidebar } from "./Sidebar";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Search = function Search(_ref) {
  var host = _ref.host,
      isSearchPage = _ref.isSearchPage;
  var theme = useTheme();
  var dispatch = useDispatch();

  var _useReduxState = useReduxState(),
      hasFacets = _useReduxState.hasFacets,
      isInitialSearch = _useReduxState.isInitialSearch,
      isLoading = _useReduxState.isLoading,
      relatedCategories = _useReduxState.relatedCategories,
      searchQuery = _useReduxState.searchQuery,
      searchHits = _useReduxState.searchHits;

  useEffect(function () {
    function handleRouteChange() {
      dispatch(searchFetch());
    }
    /**
     * Fetch the initial search results here rather than in _app.tsx
     * so that we can avoid calling unnecessary requests e.g. for
     * tactical category page that doesn't show search results.
     */


    dispatch(searchFetch());
    window.addEventListener("popstate", handleRouteChange);
    return function () {
      return window.removeEventListener("popstate", handleRouteChange);
    };
  }, [dispatch]);
  var isMobile = useMedia(["(max-width: ".concat(theme.breakpoints.md, ")")], [true]);
  var showSpinner = isLoading && !isInitialSearch;
  return ___EmotionJSX(ThemeProvider, {
    enableGlobalFontConfig: false
  }, showSpinner ? ___EmotionJSX(LoadingSpinner, null) : null, isMobile && ___EmotionJSX(SearchFacetsModal, null), ___EmotionJSX(Box, _extends({}, isSearchPage ? {
    "data-cnstrc-search": true
  } : {
    "data-cnstrc-browse": true
  }, {
    "data-cnstrc-num-results": searchHits
  }), ___EmotionJSX(SearchMigrationNudge, null), ___EmotionJSX(HeaderContent, {
    host: host
  }), relatedCategories && relatedCategories.length > 0 && ___EmotionJSX(Box, {
    display: "block",
    width: "100%",
    overflowX: "hidden",
    pl: {
      xs: 3,
      md: 7,
      lg: 3
    },
    pr: {
      xs: 3,
      md: 7
    },
    backgroundColor: "ui.backgroundColor",
    zIndex: 2
  }, ___EmotionJSX(SearchPillNavigation, {
    items: relatedCategories
  })), ___EmotionJSX(SearchContainer, {
    hasFacetsModal: isMobile,
    hasFacets: hasFacets
  }, ___EmotionJSX(Sidebar, null), ___EmotionJSX(Results, {
    isMobile: isMobile,
    host: host
  })), ___EmotionJSX(RetailMediaBannerBottom, {
    host: host,
    isMobile: isMobile,
    searchQuery: searchQuery
  })));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      hasFacets: hasFacets(state),
      isLoading: isSearchLoading(state),
      isInitialSearch: isInitialSearch(state),
      relatedCategories: relatedCategories(state),
      searchQuery: searchQuery(state),
      searchHits: searchHits(state)
    };
  });
};