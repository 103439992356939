import React, { useCallback } from "react";
import { emitTacticalCategory, useTriggerImpression } from "@catchoftheday/analytics";
import { Box, Link } from "@catchoftheday/cg-components";
import { useIsInViewport } from "@catchoftheday/utilities";
import { ThumbnailsGrid } from "../../ThumbnailsGrid";
import { SectionHeading } from "../SectionHeading";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var CategoriesGrid = function CategoriesGrid(_ref) {
  var isSearchResultsHidden = _ref.isSearchResultsHidden,
      shopAllHref = _ref.shopAllHref,
      subcategories = _ref.subcategories;
  var trackCategoryClicks = useCallback(function (name) {
    emitTacticalCategory({
      action: "click",
      label: "categories",
      property: name
    });
  }, []);

  var _useIsInViewport = useIsInViewport(),
      ref = _useIsInViewport.ref,
      inView = _useIsInViewport.inView;

  useTriggerImpression({
    emitFn: function emitFn() {
      emitTacticalCategory({
        action: "impression",
        label: "categories"
      });
    },
    inView: inView
  });
  return ___EmotionJSX(Box, {
    ref: ref
  }, ___EmotionJSX(SectionHeading, {
    title: "Categories"
  }, isSearchResultsHidden && shopAllHref && ___EmotionJSX(Link, {
    fontSize: "base",
    textDecoration: "underline",
    href: shopAllHref,
    color: "inherit",
    onClick: function onClick() {
      return trackCategoryClicks("Shop all");
    }
  }, "Shop all")), ___EmotionJSX(ThumbnailsGrid, {
    items: subcategories,
    gridProps: {
      "aria-label": "List of categories"
    },
    onItemClick: function onItemClick(item) {
      return trackCategoryClicks(item.title);
    }
  }));
};