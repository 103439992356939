import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

import React from "react";
import { Button, List, ListItem } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var KeyTabList = styled(List)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  margin: 0 16px 10px;\n  padding: 0;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n  list-style: none;\n  ", "\n"])), themeGet("colors.ui.borderColor"), themeGet("colors.ui.borderColor"), function (props) {
  return props.activeTab && "\n    border-bottom: 2px solid ".concat(themeGet("colors.secondary")(props), ";\n    margin-bottom: 14px;\n  ");
});
var KeyTab = styled(ListItem)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-grow: 1;\n  margin: 0;\n  &:first-of-type button {\n    border-left-style: solid;\n    border-left-width: 1px;\n  }\n"])));
var KeyTabButton = styled(Button)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  padding: 8px 0;\n  border: none;\n  background-color: white;\n  color: ", ";\n  border-right: 1px solid ", ";\n  border-color: ", ";\n  font-size: 12px;\n  font-weight: 100;\n  border-radius: 0;\n  &:focus {\n    outline: none;\n  }\n  ", "\n"])), themeGet("colors.typography.subText"), themeGet("colors.ui.borderColor"), themeGet("colors.ui.borderColor"), function (props) {
  return props.activeTab && "\n      background-color: ".concat(themeGet("colors.secondary")(props), ";\n      border-color: ").concat(themeGet("colors.secondary")(props), ";\n      color: white;\n    ");
});
export var SearchFacetsKeyTabs = function SearchFacetsKeyTabs(_ref) {
  var _ref$tabNames = _ref.tabNames,
      tabNames = _ref$tabNames === void 0 ? [] : _ref$tabNames,
      _ref$activeTabName = _ref.activeTabName,
      activeTabName = _ref$activeTabName === void 0 ? "" : _ref$activeTabName,
      onKeyDown = _ref.onKeyDown,
      onSwitchTab = _ref.onSwitchTab;

  if (tabNames.length <= 1) {
    return null;
  }

  return ___EmotionJSX(KeyTabList, {
    activeTab: activeTabName.length > 0
  }, tabNames.map(function (tabName) {
    return ___EmotionJSX(KeyTab, {
      key: tabName
    }, ___EmotionJSX(KeyTabButton, {
      onKeyDown: onKeyDown,
      onClick: function onClick() {
        return onSwitchTab(tabName);
      },
      activeTab: tabName === activeTabName,
      variant: "plain",
      sizeVariant: "xs"
    }, tabName));
  }));
};