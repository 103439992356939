import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["inputContainerProps", "iconSize", "checked", "name", "onChange", "value", "required", "disabled", "title"];

var _templateObject;

import React from "react";
import { styled, themeGet, useTheme } from "@catchoftheday/theme";
import { IconSVG } from "../Icon";
import { RadioGroupContext } from "../RadioGroup/RadioGroupContext";
import { Span } from "../Text";
import { jsx as ___EmotionJSX } from "@emotion/react";
var OriginalRadioInput = styled("input")(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  opacity: 0;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  margin: 0;\n  padding: 0;\n  z-index: 1;\n  /** here focus-visible and its polyfill seems works not consistent, use focus instead */\n  &:focus + svg {\n    outline: none;\n    circle:first-of-type {\n      stroke: ", ";\n    }\n  }\n\n  &:checked + svg {\n    circle:first-of-type {\n      stroke: ", ";\n    }\n    circle:last-of-type {\n      display: block;\n    }\n  }\n  &:hover + svg circle:first-of-type {\n    stroke: ", ";\n  }\n\n  &:disabled + svg {\n    circle:first-of-type {\n      stroke-width: 0;\n      fill: ", ";\n    }\n  }\n"])), themeGet("colors.secondaryDark"), themeGet("colors.secondary"), themeGet("colors.secondaryDark"), themeGet("colors.ui.borderColor"));

var RadioIcon = function RadioIcon(_ref) {
  var _theme$colors, _theme$colors$ui, _theme$colors2;

  var iconSize = _ref.iconSize,
      title = _ref.title;
  var theme = useTheme();
  return ___EmotionJSX(IconSVG, {
    viewBox: "0 0 20 20",
    "aria-hidden": "true",
    focusable: "false",
    width: iconSize,
    height: iconSize
  }, title && ___EmotionJSX("title", null, title), ___EmotionJSX("circle", {
    stroke: theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : (_theme$colors$ui = _theme$colors["ui"]) === null || _theme$colors$ui === void 0 ? void 0 : _theme$colors$ui["borderColor"],
    strokeWidth: "2",
    fill: "white",
    cx: "10",
    cy: "10",
    r: "8"
  }), ___EmotionJSX("circle", {
    display: "none",
    fill: theme === null || theme === void 0 ? void 0 : (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : _theme$colors2["secondary"],
    fillRule: "nonzero",
    cx: "10",
    cy: "10",
    r: "4"
  }));
};

export var Radio = function Radio(_ref2) {
  var inputContainerProps = _ref2.inputContainerProps,
      iconSize = _ref2.iconSize,
      checkedProp = _ref2.checked,
      nameProp = _ref2.name,
      onChangeProp = _ref2.onChange,
      valueProp = _ref2.value,
      required = _ref2.required,
      disabled = _ref2.disabled,
      title = _ref2.title,
      otherProps = _objectWithoutProperties(_ref2, _excluded);

  var checked = checkedProp;
  var name = nameProp;
  var radioGroupContext = React.useContext(RadioGroupContext);

  if (radioGroupContext) {
    if (typeof checked === "undefined") {
      checked = radioGroupContext.value === "".concat(valueProp);
    }

    if (typeof name === "undefined") {
      name = radioGroupContext.name;
    }
  }

  var onChangeHandler = function onChangeHandler(e) {
    if (onChangeProp) {
      onChangeProp(e);
    }

    if (typeof checkedProp === "undefined" && radioGroupContext !== null && radioGroupContext !== void 0 && radioGroupContext.onChange) {
      radioGroupContext.onChange(e);
    }
  };

  return ___EmotionJSX(Span, _extends({
    position: "relative"
  }, inputContainerProps), ___EmotionJSX(OriginalRadioInput, _extends({
    type: "radio",
    checked: checked,
    name: name,
    value: valueProp,
    onChange: onChangeHandler,
    "aria-checked": checked,
    "aria-required": required,
    "aria-disabled": disabled,
    required: required,
    disabled: disabled
  }, otherProps)), ___EmotionJSX(RadioIcon, {
    iconSize: iconSize,
    title: title
  }));
};
Radio.displayName = "Radio";
export default Radio;