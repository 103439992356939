import querystring from "querystring";
import React, { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Box } from "@catchoftheday/cg-components";
import { useCustomerQuery } from "@catchoftheday/customer";
import { generateOnePassConsentUrl, MigrationNudgeContainer } from "@catchoftheday/onepass";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchMigrationNudge = function SearchMigrationNudge() {
  var router = useRouter() || {};
  var pathname = router.pathname,
      query = router.query;

  var _useState = useState(pathname),
      currentPathname = _useState[0],
      setCurrentPathname = _useState[1];

  var _useState2 = useState(query),
      currentQuery = _useState2[0],
      setCurrentQuery = _useState2[1];

  var _useCustomerQuery = useCustomerQuery({
    ssr: false
  }),
      data = _useCustomerQuery.data;

  var _ref = data || {},
      customer = _ref.customer;

  var _ref2 = customer || {},
      membership = _ref2.membership;

  var popStateListener = useCallback(function () {
    if (typeof globalThis !== "undefined") {
      setCurrentPathname(globalThis.location.pathname);
      setCurrentQuery(querystring.parse(globalThis.location.search.substring(1)));
    }
  }, []);
  useEffect(function () {
    if (typeof globalThis !== "undefined") {
      globalThis.addEventListener("popstate", popStateListener);
    }

    return function () {
      if (typeof globalThis !== "undefined") {
        globalThis.removeEventListener("popstate", popStateListener);
      }
    };
  }, [popStateListener]);
  return ___EmotionJSX(React.Fragment, null, currentPathname && currentPathname === "/search" && (membership === null || membership === void 0 ? void 0 : membership.eligibleForMigration) && ___EmotionJSX(Box, {
    mb: {
      xs: 3,
      md: 7
    },
    ml: 3,
    mr: {
      xs: 3,
      md: 5
    }
  }, ___EmotionJSX(MigrationNudgeContainer, {
    CTAUrl: generateOnePassConsentUrl(currentPathname, currentQuery)
  })));
};