import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React, { useEffect, useMemo, useRef } from "react";
import { Button, Flex, Heading, IconX, LoadingSpinner } from "@catchoftheday/cg-components";
import { useActions, useShallowSelector } from "@search-app/store";
import { searchFacetsVisiblity } from "@search-app/store/search/actions";
import { hasFacets, hasFacetsApplied, isSearchLoading, searchHits, visibleFacets } from "@search-app/store/search/selectors";
import { clearAllFacets } from "@search-app/utilities/facets";
import { SearchFacets } from "../SearchFacets";
import { BodyContext } from "./SearchFacetModalContext";
import { BottomButtons, ClearButton, CloseButton, FacetModal, ModalBody, ModalContent, ModalHeader } from "./SearchFacetsModal.styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchFacetsModalInternal = function SearchFacetsModalInternal(_ref) {
  var hasFacets = _ref.hasFacets,
      hasFacetsApplied = _ref.hasFacetsApplied,
      isLoading = _ref.isLoading,
      isVisible = _ref.isVisible,
      searchHits = _ref.searchHits,
      toggleVisibility = _ref.toggleVisibility;
  useEffect(function () {
    if (true && window.document) {
      if (isVisible) {
        var _window$AndroidApp;

        window.document.documentElement.classList.add("no-scroll");
        window.document.body.classList.add("no-scroll");

        if (typeof ((_window$AndroidApp = window.AndroidApp) === null || _window$AndroidApp === void 0 ? void 0 : _window$AndroidApp.setSwipeRefresh) === "function") {
          window.AndroidApp.setSwipeRefresh(false);
        }
      } else {
        var _window$AndroidApp2;

        window.document.documentElement.classList.remove("no-scroll");
        window.document.body.classList.remove("no-scroll");

        if (typeof ((_window$AndroidApp2 = window.AndroidApp) === null || _window$AndroidApp2 === void 0 ? void 0 : _window$AndroidApp2.setSwipeRefresh) === "function") {
          window.AndroidApp.setSwipeRefresh(true);
        }
      }
    }
  }, [isVisible]);
  var bodyRef = useRef(null);
  var getScrollRef = useMemo(function () {
    return {
      bodyRef: bodyRef
    };
  }, []);

  if (true && !window.document || !hasFacets) {
    return null;
  }

  var onTouchMoveHandler = function onTouchMoveHandler(e) {
    if (e.target instanceof HTMLElement && !e.target.classList.value.match(/(MuiSlider|range-slider-handle)/)) {
      e.stopPropagation();
    }
  };

  return ___EmotionJSX(FacetModal, {
    role: "dialog",
    "aria-hidden": !isVisible,
    isVisible: isVisible
  }, ___EmotionJSX(ModalContent, null, ___EmotionJSX(ModalHeader, null, ___EmotionJSX(Flex, {
    alignItems: "center"
  }, ___EmotionJSX(CloseButton, {
    variant: "plain",
    sizeVariant: "xs",
    onClick: toggleVisibility,
    "aria-label": "Close",
    p: "0"
  }, ___EmotionJSX(IconX, {
    title: "Close",
    width: "34px",
    height: "34px"
  })), ___EmotionJSX(Heading.h4, {
    margin: "14px 0px",
    ml: "6px",
    color: "textWhite",
    fontSize: "xl"
  }, "Filters")), hasFacetsApplied && ___EmotionJSX(ClearButton, {
    onClick: clearAllFacets
  }, ___EmotionJSX(Heading.h5, {
    margin: "14px 20px",
    color: "textWhite"
  }, "clear all"))), ___EmotionJSX(ModalBody, {
    ref: bodyRef,
    onTouchMove: onTouchMoveHandler
  }, ___EmotionJSX(BodyContext.Provider, {
    value: getScrollRef
  }, ___EmotionJSX(SearchFacets, {
    isMobile: true
  }))), ___EmotionJSX(BottomButtons, null, ___EmotionJSX(Button, {
    flexGrow: 1,
    variant: "secondary",
    className: "btnViewResult",
    onClick: toggleVisibility,
    textTransform: "none",
    lineHeight: 0
  }, hasFacetsApplied ? "View ".concat(searchHits, " Result").concat(searchHits != 1 ? "s" : "") : "View Results"))), isLoading && ___EmotionJSX(LoadingSpinner, {
    hasOverlay: true,
    overlayBackgroundColor: "rgba(0, 0, 0, 0.2)",
    containerProps: {
      zIndex: 4
    }
  }));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      hasFacets: hasFacets(state),
      hasFacetsApplied: hasFacetsApplied(state),
      isLoading: isSearchLoading(state),
      isVisible: visibleFacets(state),
      searchHits: searchHits(state)
    };
  });
};

var useReduxActions = function useReduxActions() {
  return useActions({
    toggleVisibility: function toggleVisibility() {
      return searchFacetsVisiblity();
    }
  });
};
/**
 * For external API props being passed into the component
 */


export var SearchFacetsModal = function SearchFacetsModal(props) {
  var state = useReduxState();
  var actions = useReduxActions();
  return ___EmotionJSX(SearchFacetsModalInternal, _extends({}, state, actions, props));
};