import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Flex } from "../Flex";
import { PaginationItem } from "./PaginationItem";
import { PaginationItems } from "./PaginationItems";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Pagination = function Pagination(props) {
  if (props.pageTotal <= 1) {
    return null;
  }

  return ___EmotionJSX(Flex, {
    as: "nav"
  }, ___EmotionJSX(PaginationItem, _extends({
    "aria-label": "Go to previous page",
    page: props.pageCurrent - 1,
    text: "\xAB Back"
  }, props)), ___EmotionJSX(PaginationItems, props), ___EmotionJSX(PaginationItem, _extends({
    "aria-label": "Go to next page",
    page: props.pageCurrent + 1,
    text: "More \xBB"
  }, props)));
};
Pagination.displayName = "Pagination";
export default Pagination;