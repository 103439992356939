import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { ThumbnailItem } from "./ThumbnailItem";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ThumbnailsGrid = function ThumbnailsGrid(_ref) {
  var items = _ref.items,
      onItemClick = _ref.onItemClick,
      gridProps = _ref.gridProps;

  if (!items.length) {
    return null;
  }

  return ___EmotionJSX(Box, _extends({
    display: "grid",
    gridTemplateColumns: {
      xs: "repeat(3, 1fr)",
      md: "repeat(4, 1fr)",
      lg: "repeat(6, 1fr)"
    },
    rowGap: {
      xs: 4,
      md: 6
    },
    columnGap: {
      xs: 2,
      md: 4
    },
    role: "list"
  }, gridProps), items.map(function (item) {
    return ___EmotionJSX(ThumbnailItem, {
      key: "thumbnail-item-".concat(item.id),
      item: item,
      onClick: onItemClick ? function () {
        return onItemClick(item);
      } : undefined
    });
  }));
};