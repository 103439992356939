import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { Button } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
export var FacetModal = styled("div")(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: ", ";\n  position: fixed;\n  z-index: 111;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  overflow-x: hidden;\n  overflow-y: auto;\n  overscroll-behavior: none;\n"])), function (props) {
  return props.isVisible ? "block" : "none";
});
export var ModalContent = styled("div")(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background-color: ", ";\n"])), themeGet("colors.ui.backgroundColor"));
export var ModalHeader = styled("div")(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  z-index: 5;\n  top: 0;\n  right: 0;\n  left: 0;\n  height: 50px;\n  background-color: ", ";\n  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);\n"])), themeGet("colors.secondary"));
export var CloseButton = styled(Button)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: relative;\n  color: white;\n  height: 48px;\n  width: 48px;\n  text-align: center;\n"])));
export var ClearButton = styled("button")(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  background: none;\n  border: none;\n  cursor: pointer;\n"])));
export var ModalBody = styled("div")(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 50px;\n  right: 0;\n  bottom: 68px;\n  left: 0;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  overscroll-behavior: contain;\n  -webkit-overflow-scrolling: touch;\n"])));
export var BottomButtons = styled("div")(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: flex;\n  position: absolute;\n  z-index: 1;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  justify-content: center;\n  height: 68px;\n  padding: 10px;\n  background-color: white;\n  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);\n  .btnViewResult {\n    flex-grow: 1;\n  }\n"])));