import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { css } from "@catchoftheday/theme";
import { useShallowSelector } from "@search-app/store";
import { seoDescription } from "@search-app/store/search/selectors";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductPageDescription = function ProductPageDescription() {
  var _useReduxState = useReduxState(),
      seoDescription = _useReduxState.seoDescription;

  if (!seoDescription) {
    return null;
  }

  return ___EmotionJSX(Box, {
    backgroundColor: "#FAFAFA",
    m: "20px 0",
    px: {
      xs: "11px",
      md: "20px",
      lg: 0
    },
    css: css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        & h2 {\n          font-size: 1rem;\n          font-weight: 600;\n        }\n        & p {\n          font-size: 0.75rem;\n          font-weight: 100;\n        }\n      "])))
  }, ___EmotionJSX("div", {
    dangerouslySetInnerHTML: {
      __html: seoDescription
    }
  }));
};

var useReduxState = function useReduxState() {
  return useShallowSelector(function (state) {
    return {
      seoDescription: seoDescription(state)
    };
  });
};