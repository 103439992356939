import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import React from "react";
import { Heading, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DividerLabel = function DividerLabel(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Heading.h4, _extends({
    m: "0",
    p: "0 16px",
    backgroundColor: "ui.backgroundColor",
    borderY: "1px solid",
    borderColor: "ui.borderColor",
    height: "24px",
    position: "sticky",
    top: "0",
    style: {
      zIndex: 1,
      boxSizing: "content-box"
    }
  }, props), ___EmotionJSX(Span, {
    width: "20px",
    lineHeight: "24px",
    textAlign: "center",
    display: "inline-block",
    fontWeight: "normal"
  }, children));
};