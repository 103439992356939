import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
export function filterObject(obj) {
  return Object.entries(obj).reduce(function (acc, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    if (value !== undefined && value !== null && value !== "") {
      acc[key] = value;
    }

    return acc;
  }, {});
}