import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { Placeholder } from "@search-app/components/Placeholder";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopNavigationPlaceholder = function ShopNavigationPlaceholder() {
  return ___EmotionJSX(Box, {
    height: "138px"
  }, ___EmotionJSX(Placeholder, {
    uniqueKey: "shop-navigation-placeholder",
    width: "100%",
    height: "100%"
  }, ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    width: "100%",
    height: "100%"
  })));
};