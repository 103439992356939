import React from "react";
import { Button, Tag, Truncate } from "@catchoftheday/cg-components";
import { SEARCH_URL_PARAM_FACET } from "@search-app/helpers/search/search.helpers.params";
import { clearAllFacets } from "@search-app/utilities/facets";
import { SearchFacetsBody } from "../SearchFacetsBody";
import { SearchFacetsContainer } from "../SearchFacetsContainer";
import { SearchFacetsHeader } from "../SearchFacetsHeader";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchFacetsApplied = function SearchFacetsApplied(_ref) {
  var id = _ref.id,
      appliedFacets = _ref.appliedFacets,
      isExpanded = _ref.isExpanded,
      onExpandChange = _ref.onExpandChange,
      onChange = _ref.onChange,
      onClear = _ref.onClear,
      isMobile = _ref.isMobile;
  var facetId = "".concat(SEARCH_URL_PARAM_FACET, "[").concat(id, "]");
  var totalSelected = appliedFacets.length;
  return ___EmotionJSX(SearchFacetsContainer, {
    id: facetId
  }, ___EmotionJSX(SearchFacetsHeader, {
    id: facetId,
    isExpanded: isExpanded,
    title: "Applied filters",
    selectedLabel: "applied",
    onExpandChange: onExpandChange,
    totalSelected: totalSelected
  }), ___EmotionJSX(SearchFacetsBody, {
    isExpanded: isExpanded,
    p: "0 16px"
  }, appliedFacets.map(function (_ref2) {
    var id = _ref2.id,
        name = _ref2.name,
        facetName = _ref2.facetName,
        facetType = _ref2.facetType;
    return ___EmotionJSX(Tag, {
      key: id,
      onClick: function onClick() {
        if ((facetType === "range" || facetType === "nested") && onClear) {
          onClear({
            facet: facetName,
            type: facetType
          });
        } else {
          onChange({
            facet: facetName,
            value: id
          });
        }
      },
      m: "0 10px 10px 0",
      "aria-label": name
    }, ___EmotionJSX(Truncate, {
      fontWeight: "normal"
    }, name));
  }), !isMobile && ___EmotionJSX(Button, {
    variant: "secondary",
    sizeVariant: "sm",
    width: "100%",
    outline: true,
    onClick: clearAllFacets,
    mb: "16px"
  }, "Clear All Filters")));
};