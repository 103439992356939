/**
 * Scroll back to top of page
 */
var scrollTop = function scrollTop() {
  try {
    window.scrollBy({
      top: window.pageYOffset * -1,
      behavior: "smooth"
    });
  } catch (error) {
    window.scroll(0, 0);
  }
};

export default scrollTop;