import React from "react";
import { Box, Flex } from "@catchoftheday/cg-components";
import { Placeholder } from "@search-app/components/Placeholder";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var HeaderContentPlaceholder = function HeaderContentPlaceholder(_ref) {
  var isMobile = _ref.isMobile,
      hasSearchFacetToggle = _ref.hasSearchFacetToggle,
      hasSearchSortOptions = _ref.hasSearchSortOptions;
  return ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    mb: "24px",
    width: "100%"
  }, ___EmotionJSX(Box, {
    width: "413px",
    height: "63.5px",
    marginLeft: isMobile ? "10px" : 0
  }, ___EmotionJSX(Placeholder, {
    uniqueKey: "header-content-1",
    width: isMobile ? 200 : 413,
    height: 63,
    viewBox: isMobile ? "0 0 200 63" : "0 0 413 63"
  }, ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    width: "100%",
    height: "33px"
  }), ___EmotionJSX("rect", {
    x: "0",
    y: "45",
    width: "20%",
    height: "20px"
  }))), isMobile && hasSearchFacetToggle && ___EmotionJSX(Box, {
    width: "107px",
    marginRight: "10px"
  }, ___EmotionJSX(Placeholder, {
    uniqueKey: "header-content-2",
    width: 107,
    height: 46,
    viewBox: "0 0 107 46"
  }, ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    width: "107px",
    height: "46px"
  }))), !isMobile && hasSearchSortOptions && ___EmotionJSX(Box, {
    marginRight: "20px"
  }, ___EmotionJSX(Placeholder, {
    uniqueKey: "header-content-3",
    width: 250,
    height: 43,
    viewBox: "0 0 250 43"
  }, ___EmotionJSX("rect", {
    width: "250px",
    height: "43px"
  }))));
};