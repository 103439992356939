import React from "react";
import { Box, Flex } from "@catchoftheday/cg-components";
import { ProductCardPlaceholder } from "@catchoftheday/common-vendors";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ResultsPlaceholder = function ResultsPlaceholder() {
  return ___EmotionJSX(Flex, {
    flexWrap: "wrap"
  }, Array(8).fill(null).map(function (_, i) {
    return ___EmotionJSX(Box, {
      key: "product-card-placeholder-".concat(i),
      width: {
        sm: "50%",
        lg: "33%",
        xl: "25%"
      },
      pb: "20px",
      flexGrow: 1,
      px: {
        xs: "2px",
        md: "10px"
      }
    }, ___EmotionJSX(ProductCardPlaceholder, {
      uniqueKey: "product-card-placeholder-".concat(i),
      minWidth: "200px",
      minHeight: "443px"
    }));
  }));
};