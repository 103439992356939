import React from "react";
import { Box, ImageResponsive, Link } from "@catchoftheday/cg-components";
import { Placeholder } from "@search-app/components/Placeholder";
import { useCategoryFeaturedEventQuery } from "@search-app/generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var EventBanner = function EventBanner(_ref) {
  var _data$eventBySlug;

  var eventId = _ref.eventId,
      onClick = _ref.onClick;

  var _useCategoryFeaturedE = useCategoryFeaturedEventQuery({
    variables: {
      eventId: eventId.toString()
    }
  }),
      data = _useCategoryFeaturedE.data,
      loading = _useCategoryFeaturedE.loading,
      error = _useCategoryFeaturedE.error;

  if (loading) {
    return ___EmotionJSX(Placeholder, {
      width: "100%",
      display: "block",
      role: "progressbar",
      uniqueKey: "event-banner-".concat(eventId),
      height: 250
    }, ___EmotionJSX("rect", {
      x: "0",
      y: "0",
      width: "100%",
      height: "250px"
    }));
  }

  if (error || !(data !== null && data !== void 0 && (_data$eventBySlug = data.eventBySlug) !== null && _data$eventBySlug !== void 0 && _data$eventBySlug.event) || !data.eventBySlug.event.image) {
    return null;
  }

  var _data$eventBySlug$eve = data.eventBySlug.event,
      eventUrl = _data$eventBySlug$eve.url,
      title = _data$eventBySlug$eve.title,
      image = _data$eventBySlug$eve.image;
  var single = image.single;
  var imageUrl = single.url,
      altText = single.altText,
      width = single.width,
      height = single.height;
  var imageRatio = height / width * 100;
  return ___EmotionJSX(Box, null, ___EmotionJSX(Link, {
    onClick: onClick,
    href: eventUrl,
    "aria-label": title
  }, ___EmotionJSX(ImageResponsive, {
    width: "100%",
    height: "auto",
    ratio: imageRatio,
    lazyLoad: true,
    src: imageUrl,
    alt: altText
  })));
};