import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useEffect, useState } from "react";
import { Handles, Rail, Slider, Ticks, Tracks } from "react-compound-slider";
import { styled } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Input } from "../Input";
import { Label } from "../Label";
import { Span } from "../Text";
import { SliderHandle, SliderTick, SliderTrack } from "./RangeSliderParts";
import { jsx as ___EmotionJSX } from "@emotion/react";
var sliderStyle = {
  position: "relative",
  width: "100%"
};
var RangeInputWrap = styled(Label)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  margin-bottom: 0;\n  font-weight: 400;\n  width: auto;\n  min-width: 100px;\n  > span {\n    position: absolute;\n    left: 0.5rem;\n    top: 50%;\n    margin-top: -10px;\n    height: 20px;\n    font-weight: 400;\n  }\n"])));
export var RangeSlider = function RangeSlider(_ref) {
  var defaultValues = _ref.defaultValues,
      minMaxValues = _ref.minMaxValues,
      onChange = _ref.onChange,
      inputProps = _ref.inputProps,
      disabled = _ref.disabled,
      containerProps = _ref.containerProps,
      tickCount = _ref.tickCount,
      _ref$step = _ref.step,
      step = _ref$step === void 0 ? 1 : _ref$step,
      _ref$mode = _ref.mode,
      mode = _ref$mode === void 0 ? 3 : _ref$mode,
      _ref$unitType = _ref.unitType,
      unitType = _ref$unitType === void 0 ? "$" : _ref$unitType,
      _ref$showInputs = _ref.showInputs,
      showInputs = _ref$showInputs === void 0 ? true : _ref$showInputs,
      displayUnitMin = _ref.displayUnitMin,
      displayUnitMax = _ref.displayUnitMax;

  var _useState = useState(defaultValues),
      values = _useState[0],
      setValues = _useState[1];

  var _useState2 = useState(defaultValues),
      update = _useState2[0],
      setUpdate = _useState2[1];

  var updateValues = function updateValues(e) {
    var updateOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var input = e.target;
    var value = input.value ? Number(input.value) : -1;
    var index = input.name === "minValue" ? 0 : 1;

    if (!updateOnly && index === 0 && value >= values[1]) {
      value = values[1] - step;
    }

    if (!updateOnly && index === 1 && value <= values[0]) {
      value = values[0] + step;
    }

    var updatedValues = _toConsumableArray(values);

    updatedValues[index] = value;
    setUpdate(updatedValues);

    if (!updateOnly) {
      setValues(updatedValues);
      onChange(updatedValues);
    }
  };

  var onInputChange = function onInputChange(e) {
    updateValues(e, true);
  };

  var keyChange = function keyChange(e) {
    if (e.key === "Enter") {
      updateValues(e);
    }
  };

  var blurChange = function blurChange(e) {
    updateValues(e);
  };

  var onRangeUpdate = function onRangeUpdate(update) {
    setUpdate(update);
  };

  var onRangeChange = function onRangeChange(newValues) {
    var unchanged = newValues.every(function (val, index) {
      return val === values[index];
    });

    if (!unchanged) {
      setValues(newValues);
      onChange(newValues);
    }
  };

  var commonInputProps = {
    onChange: onInputChange,
    onKeyDown: keyChange,
    onBlur: blurChange,
    disabled: disabled,
    type: "number",
    min: minMaxValues[0],
    max: minMaxValues[1],
    p: "0.5rem 0.5rem 0.5rem 2rem"
  };
  useEffect(function () {
    setValues(defaultValues);
    setUpdate(defaultValues);
  }, [defaultValues]);
  return ___EmotionJSX(Flex, _extends({
    as: "section",
    flexDirection: "column",
    width: "100%"
  }, containerProps), showInputs && ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    mb: 5
  }, ___EmotionJSX(RangeInputWrap, {
    id: "price-slider-min-value",
    "aria-label": "Minimum value"
  }, ___EmotionJSX(Span, null, unitType), ___EmotionJSX(Input, _extends({
    value: update[0] === -1 ? "" : update[0],
    name: "minValue"
  }, commonInputProps, inputProps))), ___EmotionJSX(RangeInputWrap, {
    id: "price-slider-max-value",
    "aria-label": "Maximum value"
  }, ___EmotionJSX(Span, null, unitType), ___EmotionJSX(Input, _extends({
    value: update[1] === -1 ? "" : update[1],
    name: "maxValue"
  }, commonInputProps, inputProps)))), ___EmotionJSX(Box, {
    px: "12px"
  }, ___EmotionJSX(Slider, {
    mode: mode,
    step: step,
    domain: minMaxValues,
    rootStyle: sliderStyle,
    onUpdate: onRangeUpdate,
    onChange: onRangeChange,
    values: values,
    disabled: disabled
  }, ___EmotionJSX(Rail, null, function (_ref2) {
    var getRailProps = _ref2.getRailProps;
    return ___EmotionJSX(Span, _extends({
      position: "absolute",
      width: "100%",
      height: "2px",
      borderRadius: "1px",
      backgroundColor: "typography.subText",
      style: {
        transform: "translate(0%, -50%)",
        cursor: disabled ? "not-allowed" : "pointer"
      }
    }, getRailProps()));
  }), ___EmotionJSX(Handles, null, function (_ref3) {
    var handles = _ref3.handles,
        getHandleProps = _ref3.getHandleProps;
    return ___EmotionJSX("div", null, handles.map(function (handle, index) {
      return ___EmotionJSX(SliderHandle, {
        key: handle.id,
        handle: handle,
        domain: minMaxValues,
        getHandleProps: getHandleProps,
        disabled: disabled,
        index: index
      });
    }));
  }), ___EmotionJSX(Tracks, {
    left: false,
    right: false
  }, function (_ref4) {
    var tracks = _ref4.tracks,
        getTrackProps = _ref4.getTrackProps;
    return ___EmotionJSX("div", null, tracks.map(function (_ref5) {
      var id = _ref5.id,
          source = _ref5.source,
          target = _ref5.target;
      return ___EmotionJSX(SliderTrack, {
        key: id,
        source: source,
        target: target,
        getTrackProps: getTrackProps,
        disabled: disabled
      });
    }));
  }), tickCount && tickCount > 0 ? ___EmotionJSX(Ticks, {
    count: tickCount
  }, function (_ref6) {
    var ticks = _ref6.ticks;
    return ___EmotionJSX("div", null, ticks.map(function (tick) {
      return ___EmotionJSX(SliderTick, {
        key: tick.id,
        tick: tick,
        count: ticks.length,
        unitType: unitType
      });
    }));
  }) : ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    pt: 3,
    ml: "-12px",
    mr: "-12px"
  }, ___EmotionJSX(Span, {
    fontSize: "xs"
  }, displayUnitMin || "Min ".concat(unitType).concat(minMaxValues[0])), ___EmotionJSX(Span, {
    fontSize: "xs"
  }, displayUnitMax || "Max ".concat(unitType).concat(minMaxValues[1]))))));
};
RangeSlider.displayName = "RangeSlider";
export default RangeSlider;