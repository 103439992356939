import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SidebarPlaceholder = function SidebarPlaceholder() {
  var createLoadingPlaceholders = function createLoadingPlaceholders(count) {
    return Array.from(Array(count).keys()).map(function (i, _) {
      return ___EmotionJSX(Box, {
        width: "312px",
        height: "50px",
        backgroundColor: "white",
        marginTop: "10px",
        key: "facets-placeholder-".concat(i)
      });
    });
  };

  return ___EmotionJSX(Box, {
    as: "aside",
    width: "327px",
    pr: "15px",
    position: "relative"
  }, ___EmotionJSX(Box, {
    width: "312px",
    height: "50px",
    backgroundColor: "white"
  }), createLoadingPlaceholders(7));
};