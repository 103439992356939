import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { Flex, HorizontalNav, Pill } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchPillNavigation = function SearchPillNavigation(_ref) {
  var items = _ref.items,
      pillContainerProps = _ref.pillContainerProps;
  var slides = items.map(function (_ref2, index) {
    var name = _ref2.name,
        url = _ref2.url,
        selected = _ref2.selected;
    return ___EmotionJSX(Flex, {
      key: index,
      my: "auto",
      ml: index == 0 ? 0 : 1,
      mr: index == items.length ? 0 : 1
    }, ___EmotionJSX(Pill, {
      label: name,
      checked: selected,
      containerProps: _objectSpread({
        flex: "1 0 auto",
        border: "solid 1px",
        borderColor: "ui.borderColor",
        background: "white",
        height: 42,
        paddingX: 5,
        role: "menuitem"
      }, pillContainerProps),
      url: url
    }));
  });
  return ___EmotionJSX(Flex, {
    height: 70
  }, ___EmotionJSX(HorizontalNav, {
    navItems: slides
  }));
};