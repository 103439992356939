import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { Box, Carousel, Link, Text } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { Placeholder } from "@search-app/components/Placeholder";
import { jsx as ___EmotionJSX } from "@emotion/react";
var NavItem = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  transition: all 0.3s;\n  border-bottom: 4px solid transparent;\n  &:hover,\n  &.active {\n    border-bottom-color: ", ";\n    p {\n      color: ", ";\n    }\n  }\n  &.active svg * {\n    stroke: ", ";\n  }\n"])), themeGet("colors.secondary"), themeGet("colors.secondary"), themeGet("colors.secondary"));
var NavText = styled(Text)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex: 1 1 auto;\n  align-items: center;\n"])));
var NavLink = styled(Link)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  flex: 1 1 100%;\n  justify-content: flex-end;\n  flex-direction: column;\n  align-items: center;\n  svg * {\n    stroke: ", ";\n  }\n  &:hover {\n    text-decoration: none;\n  }\n  &:hover svg * {\n    stroke: ", ";\n  }\n"])), themeGet("colors.typography.subText"), themeGet("colors.secondary"));
var NavSVG = styled(SVG)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  flex: 0 0 100px;\n"])));
export var ShopNavigation = function ShopNavigation(_ref) {
  var shopNavigationItems = _ref.shopNavigationItems;

  var _useState = useState([]),
      slideErrors = _useState[0],
      setSlideErrors = _useState[1];

  var CustomPlaceholder = function CustomPlaceholder(_ref2) {
    var title = _ref2.title,
        animate = _ref2.animate;
    return ___EmotionJSX(Placeholder, {
      uniqueKey: title,
      width: 100,
      height: 100,
      viewBox: "0 0 100 100",
      title: title,
      animate: animate
    }, ___EmotionJSX("circle", {
      cx: "50",
      cy: "50",
      r: "40"
    }));
  };

  var generateKey = function generateKey(pre) {
    return "".concat(pre, "_").concat(new Date().getTime());
  };

  var getSlides = function getSlides() {
    return shopNavigationItems.map(function (item, index) {
      return ___EmotionJSX(NavItem, {
        key: generateKey(item.name),
        className: item.active ? "active" : ""
      }, ___EmotionJSX(NavLink, {
        display: "flex",
        position: "relative",
        href: item.url
      }, slideErrors.includes(item.name) && ___EmotionJSX(CustomPlaceholder, {
        title: item.name,
        animate: false
      }), ___EmotionJSX(NavSVG, {
        width: "100px",
        height: "100px",
        src: "https://s.catch.com.au/".concat(item.icon_url),
        title: item.name,
        uniquifyIDs: true,
        preProcessor: function preProcessor(code) {
          return (// ensures all svg's have unique classes to prevent style conflicts.
            code.replace(/(\.|")st/g, "$1st-".concat(index))
          );
        },
        loader: ___EmotionJSX(CustomPlaceholder, {
          title: "Loading...",
          animate: true
        }),
        onError: function onError() {
          setSlideErrors([].concat(_toConsumableArray(slideErrors), [item.name]));
        }
      }), ___EmotionJSX(NavText, {
        display: "flex",
        textTransform: "uppercase",
        textAlign: "center",
        m: "10px 10px 6px",
        color: "typography.subText"
      }, item.name)));
    });
  };

  return ___EmotionJSX(Carousel, {
    slides: getSlides(),
    slidesPerBreakpoint: [8, 6, 4, 2, 1],
    containerProps: {
      px: "40px",
      mb: "15px",
      borderBottom: "solid 1px",
      borderColor: "ui.borderColor"
    },
    buttonOffset: [-40, 0],
    carouselName: "Shop navigation",
    dragEnabled: false
  });
};