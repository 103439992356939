import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Checkbox, Flex, Label, Radio } from "@catchoftheday/cg-components";
import { FacetCount } from "../../SearchFacets.styles";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchFacetsCheckbox = function SearchFacetsCheckbox(_ref) {
  var count = _ref.count,
      description = _ref.description,
      id = _ref.id,
      checked = _ref.checked,
      _ref$isRadio = _ref.isRadio,
      isRadio = _ref$isRadio === void 0 ? false : _ref$isRadio,
      name = _ref.name,
      _onChange = _ref.onChange,
      value = _ref.value,
      focused = _ref.focused;
  var FacetInput = isRadio ? Radio : Checkbox;
  var inputProps = {
    id: id,
    inputContainerProps: {
      mr: "8px"
    },
    name: name,
    onChange: function onChange(e) {
      return _onChange(value, e.target.checked);
    },
    checked: checked,
    iconSize: "20px",
    title: typeof description === "string" ? description : undefined
  };
  return ___EmotionJSX(Label, {
    display: "block",
    hidden: false,
    htmlFor: id,
    mb: 0,
    pb: 3,
    textStyle: "text",
    fontSize: "sm",
    fontWeight: "light"
  }, ___EmotionJSX(Flex, {
    alignItems: "flex-start"
  }, ___EmotionJSX(FacetInput, _extends({}, inputProps, !isRadio ? {
    focused: focused
  } : {})), ___EmotionJSX(Flex, {
    alignItems: "center"
  }, description, count && ___EmotionJSX(FacetCount, null, "(".concat(count, ")")))));
};