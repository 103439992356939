import React, { Fragment, useCallback } from "react";
import { SEARCH_FACET_TYPE_NESTED } from "@search-app/helpers/search/search.helpers.facets";
import { SEARCH_URL_PARAM_FACET } from "@search-app/helpers/search/search.helpers.params";
import { facetAppliedCount } from "@search-app/utilities/facets/applied";
import getSelectedDrilldownCategoryLabel from "@search-app/utilities/search/search.utilities.getSelectedDrilldownCategoryLabel";
import { FacetList, FacetSubList } from "../../SearchFacets.styles";
import { SearchFacetsBody } from "../SearchFacetsBody";
import { SearchFacetsContainer } from "../SearchFacetsContainer";
import { SearchFacetsHeader } from "../SearchFacetsHeader";
import { SearchFacetsNestedItem } from "../SearchFacetsNestedItem";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SearchFacetsNested = function SearchFacetsNested(_ref) {
  var facet = _ref.facet,
      isExpanded = _ref.isExpanded,
      onClear = _ref.onClear,
      onChange = _ref.onChange,
      onExpandChange = _ref.onExpandChange,
      noncollapsible = _ref.noncollapsible,
      searchTimeout = _ref.searchTimeout;
  var id = facet.id,
      name = facet.name,
      values = facet.values;
  var facetId = "".concat(SEARCH_URL_PARAM_FACET, "[").concat(id, "]");
  var selectedLabel = values && getSelectedDrilldownCategoryLabel(values);
  var totalSelected = facetAppliedCount(facet);

  var handleClick = function handleClick(currentId, parentId, selected) {
    if (onClear && selected && !parentId) {
      return onClear({
        facet: id
      });
    }

    return onChange({
      facet: facet.id,
      value: currentId,
      type: SEARCH_FACET_TYPE_NESTED
    });
  };

  function renderListItem(items) {
    var parentId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var selected = items.selected,
        name = items.name,
        count = items.count,
        currentId = items.id,
        values = items.values;

    if (selected && values !== null && values !== void 0 && values.length) {
      return ___EmotionJSX(Fragment, {
        key: currentId
      }, ___EmotionJSX(SearchFacetsNestedItem, {
        count: count,
        name: name,
        onClick: function onClick() {
          return handleClick(currentId, parentId, selected);
        },
        selected: selected
      }), ___EmotionJSX(FacetSubList, null, renderList(values, currentId)));
    }

    return ___EmotionJSX(SearchFacetsNestedItem, {
      key: currentId,
      count: count,
      name: name,
      onClick: function onClick() {
        return handleClick(currentId, parentId, selected);
      },
      selected: selected
    });
  }

  function renderList(values) {
    var parentId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    if (values !== null && values !== void 0 && values.find(function (facet) {
      return facet.selected;
    })) {
      return values.filter(function (facet) {
        return facet.selected;
      }).map(function (facet) {
        return renderListItem(facet, parentId);
      });
    }

    return values === null || values === void 0 ? void 0 : values.map(function (facet) {
      return renderListItem(facet, parentId);
    });
  }

  var onClearHandler = useCallback(function () {
    if (onClear) {
      onClear({
        facet: id
      });
    }
  }, [onClear, id]);
  return ___EmotionJSX(SearchFacetsContainer, {
    id: facetId
  }, ___EmotionJSX(SearchFacetsHeader, {
    id: facetId,
    isExpanded: isExpanded,
    title: name,
    searchTimeout: searchTimeout,
    selectedLabel: selectedLabel,
    onClear: onClearHandler,
    onExpandChange: onExpandChange,
    noncollapsible: noncollapsible,
    totalSelected: totalSelected
  }), ___EmotionJSX(SearchFacetsBody, {
    isExpanded: isExpanded
  }, ___EmotionJSX(FacetList, null, renderList(facet.values, null))));
};